import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Modal from 'react-modal'
// mui
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import styled from '@mui/material/styles/styled'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Popover from '@mui/material/Popover'
import useTheme from '@mui/material/styles/useTheme'
import LoadingButton from '@mui/lab/LoadingButton'
// constants
import { NOT_OWNER_WORK } from '../../../constants/dc-admin'
// redux
import {
  clearMemoList,
  getProfileDetails,
  submitRequestReject,
  // submitUpdateProcessStatus,
} from '../slices/applicant-details'
import { useDispatch, useSelector } from '../../../redux/store'
import {
  selectTableRows,
  selectCrudFormData,
  selectInitialProfile,
} from '../../../redux/selector'
import { openDialog, closeDialog } from '../../../components/dialog/slices'
// components
import { CHeader } from '../../../components/CRUD/components/Header'
import Dropdown from '../../../components/Input/Dropdown'
import { CustomTextArea } from '../../../components/CRUD/components/CustomTextArea'
import { initialConfig } from '../../../components/CRUD/handler/initialConfig'
import { fetchDataList } from '../../../components/CRUD/handler/fetchDataList'
import { crudConfig as initialCrudConfig } from '../../MasterData/reasons-drop-prospect-by-dc/crudConfig'
import { mutateFilterPropToBody } from '../../MasterData/reasons-drop-prospect-by-dc/features/Listing/mutation/filterPropToBody'
// hooks
import useGetMessageOwnerDialog from '../../../hooks/useGetMessageOwnerDialog'
//
import { breadcrumb } from './model/breadcrumb'
import Checklist from './Checklist'
import ProfileHeader from './ProfileHeader'
import TabFeatures from './TabFeatures'
import { AuditHistoryDrawer } from './Drawer'
import {
  ApiStatus,
  ProcessResultApprovalStatus,
} from '../../../constants/status'
import { enumStatus } from '../../../components/CRUD/enum/status'

import { ShowFile } from './ShowFile'
import { ShowFixedFileBox } from './ShowFixedFileBox'
import { openDrawer } from '../../../components/CustomDrawer/slices'
import { HistoryAudit } from './HistoryAudit'
import { HistoryProcess } from './HistoryProcess'
import { DIALOG_TYPE_STRING } from '../../../components/dialog/constants'
import {
  closeCustomDialog,
  openCustomDialog,
} from '../../../components/CustomDialog/slices'
import { callFullFormReject, callUpdateContractor } from './services/thunk'
import { disabledBeforeReview } from './handler/disabledButton'
import { HistoryDecision } from './FormRequestDecision/HistoryDecision'
import { getTitleNotOwner } from './handler/getTitleNotOwner'
import useLocalStorage from '@rehooks/local-storage'
import _ from 'lodash'
import { permissionItem } from '../../../constants/permission'

const ContainerStyled = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 3),
  paddingBottom: theme.spacing(3),
}))

Modal.setAppElement('#root')

type ReasonRejectOption = {
  id: number
  uuid: string
  name: string
  status: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  dropFromSystem: string
}

export default function ApplicantDetails() {
  const [user] = useLocalStorage('user')
  const uniqPermissions = _.get(user, 'uniqPermissions', [])
  const isPermission = _.includes(uniqPermissions, permissionItem.DC_ADMIN)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const [auditHistoryDrawer, setAuditHistoryDrawer] = useState(false)

  const messageDialog = useGetMessageOwnerDialog(
    'ยืนยัน',
    'คุณต้องการปฏิเสธผู้สมัครใช่หรือไม่'
  )

  const initProfile = useSelector(selectInitialProfile)

  const reasonRejectOptions = useSelector(
    selectTableRows
  ) as ReasonRejectOption[]

  const [isOpenModal, setIsOpenModal] = useState(false)

  const { prospectId } = useParams()

  const formData = useSelector(selectCrudFormData) as {
    reasonReject: ReasonRejectOption
    rejectDetail: string
  }
  const { isLoading } = useSelector((state) => state.applicantDetails)

  const closeModal = () => {
    setIsOpenModal(false)

    document.body.style.overflow = 'auto'
  }

  const openModal = () => {
    setIsOpenModal(true)

    document.body.style.overflow = 'hidden'
  }

  const dispatch = useDispatch()

  const theme = useTheme()

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRequestReject = useCallback(() => {
    const { uuid: dropProspectByDCUuid, name: reasonText } =
      formData.reasonReject

    if (messageDialog.title === NOT_OWNER_WORK) {
      dispatch(
        openDialog({
          type: '',
          ...messageDialog,
          handleConfirm() {
            dispatch(
              submitRequestReject({
                fullFormUuid: String(initProfile?.fullformUuid),
                dropProspectByDCUuid,
                reasonText,
                detail: formData.rejectDetail,
              })
            )
            dispatch(closeDialog())
            closeModal()
            window.location.reload()
          },
          isCloseDialog: false,
        })
      )

      return
    }

    dispatch(
      submitRequestReject({
        fullFormUuid: String(initProfile?.fullformUuid),
        dropProspectByDCUuid,
        reasonText,
        detail: formData.rejectDetail,
      })
    )

    closeModal()
  }, [dispatch, formData, initProfile?.fullformUuid, messageDialog])
  const dialogTitle = getTitleNotOwner(initProfile, 'ยืนยัน')
  const handleFullFormReject = () => {
    const { uuid: dropProspectByDCUuid, name: reasonText } =
      formData.reasonReject

    dispatch(
      openCustomDialog({
        type: DIALOG_TYPE_STRING.CONFIRM,
        title: dialogTitle,
        message: 'คุณต้องการปฏิเสธผู้สมัครใช่หรือไม่',
        handleConfirm: async () => {
          const { payload } = await dispatch(
            callFullFormReject({
              fullFormUuid: String(initProfile?.fullformUuid),
              dropProspectByDCUuid,
              reasonText,
              detail: formData.rejectDetail,
            })
          )

          if (payload?.status && payload?.status !== ApiStatus.ERROR) {
            dispatch(
              openCustomDialog({
                type: DIALOG_TYPE_STRING.SUCCESS,
                title: 'สำเร็จ',
                message: 'ขอปฏิเสธผู้สมัครเรียบร้อยแล้ว',
                handleOK: () => {
                  window.location.reload()
                },
              })
            )
          } else {
            dispatch(closeCustomDialog())
          }
        },
      })
    )
  }
  const handleAcceptReview = async () => {
    dispatch(
      openCustomDialog({
        type: DIALOG_TYPE_STRING.CONFIRM,
        title: 'ยืนยัน',
        message: 'ต้องการรับงานนี้ใช่หรือไม่',
        handleConfirm: async () => {
          const { payload } = await dispatch(
            callUpdateContractor({
              fullFormUuid: String(initProfile?.fullformUuid),
            })
          )

          if (payload?.status && payload?.status !== ApiStatus.ERROR) {
            dispatch(
              openCustomDialog({
                type: DIALOG_TYPE_STRING.SUCCESS,
                title: 'สำเร็จ',
                message: 'รับงานสำเร็จแล้ว',
                handleOK: () => {
                  window.location.reload()
                },
              })
            )
          } else {
            dispatch(closeCustomDialog())
          }
        },
      })
    )
  }
  // const handleOpenDrawer = () => {
  //   setAnchorEl(null)
  //   setAuditHistoryDrawer(true)
  // }

  const toggleDrawer = (newValue: boolean) => () => {
    setAuditHistoryDrawer(newValue)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'audit-history-popover' : undefined

  const handleInitialConfig = useCallback(async () => {
    // get reasons drop prospect by dc from master data
    initialConfig(initialCrudConfig, true)

    await new Promise((resolve) => setTimeout(resolve, 500))

    fetchDataList(() => ({
      ...mutateFilterPropToBody(),
      status: [enumStatus.ACTIVE],
    }))
  }, [])

  useEffect(() => {
    handleInitialConfig()
  }, [handleInitialConfig, isOpenModal])

  useEffect(() => {
    if (prospectId) {
      dispatch(getProfileDetails({ prospectId }))
      dispatch(clearMemoList())
    }
  }, [dispatch, initProfile?.fullformUuid, prospectId])

  return (
    <>
      <ContainerStyled>
        {/* HEADER SECTION */}
        <CHeader
          title="ตรวจสอบใบสมัคร"
          breadcrumbs={breadcrumb}
          action={
            <Stack direction="row" alignItems="center" spacing={2}>
              {![
                ProcessResultApprovalStatus.DCV_DROPPED,
                ProcessResultApprovalStatus.DCV_UPDATED_AGENT_CODE,
              ].includes(
                initProfile?.progressBarStatus as ProcessResultApprovalStatus
              ) && (
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={openModal}
                  disabled={!isPermission}
                >
                  ปฏิเสธผู้สมัคร
                </Button>
              )}
              {initProfile?.progressBarStatus ===
              ProcessResultApprovalStatus.DS_REVIEWED ? (
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ width: 113 }}
                  onClick={handleAcceptReview}
                  disabled={!isPermission}
                >
                  รับงาน
                </Button>
              ) : null}
              <IconButton
                aria-describedby={id}
                disabled={disabledBeforeReview(initProfile)}
                onClick={handlePopoverClick}
              >
                <MoreVertOutlinedIcon fontSize="medium" color="action" />
              </IconButton>
              {/* Popover */}
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Box
                  sx={{
                    p: 1,
                    px: 3,
                    ':hover': {
                      backgroundColor: '#F1F1F1',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => {
                    dispatch(
                      openDrawer({
                        content: <HistoryAudit />,
                      })
                    )
                    handleClose()
                  }}
                >
                  <Typography variant="body1" color="text.primary">
                    ประวัติการขอแก้ไขเอกสาร
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: 1,
                    px: 3,
                    ':hover': {
                      backgroundColor: '#F1F1F1',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => {
                    dispatch(
                      openDrawer({
                        content: <HistoryProcess />,
                      })
                    )
                    handleClose()
                  }}
                >
                  <Typography variant="body1" color="text.primary">
                    ประวัติการดำเนินการ
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: 1,
                    px: 3,
                    ':hover': {
                      backgroundColor: '#F1F1F1',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => {
                    dispatch(
                      openCustomDialog({
                        type: DIALOG_TYPE_STRING.CUSTOM,

                        content: <HistoryDecision isEdit={false} />,
                        boxSx: { pr: 0, pe: 0 },
                      })
                    )
                  }}
                >
                  <Typography variant="body1" color="text.primary">
                    ดูประวัติการส่งพิจารณาเพิ่มเติม
                  </Typography>
                </Box>
              </Popover>
            </Stack>
          }
        />

        {/* Audit history drawer */}
        <AuditHistoryDrawer
          open={auditHistoryDrawer}
          toggleDrawer={toggleDrawer}
        />

        {/* CONTENT SECTION */}
        <Stack gap={3}>
          <Checklist />
          <ProfileHeader />
          <TabFeatures />
        </Stack>
      </ContainerStyled>

      <Modal
        isOpen={isOpenModal}
        onRequestClose={closeModal}
        contentLabel="Reject applicant modal"
        style={{
          overlay: {
            backgroundColor: `${theme.palette.common.black}BF`,
            zIndex: 1200,
          },
          content: {
            maxWidth: '678px',
            width: '100%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
            boxShadow: `0px 9px 46px 8px ${theme.palette.common.black}1F,
                        0px 24px 38px 3px ${theme.palette.common.black}24,
                        0px 11px 15px -7px ${theme.palette.common.black}33`,
          },
        }}
      >
        <Box padding={2}>
          <Typography variant="h6">ขอปฏิเสธผู้สมัคร</Typography>
        </Box>
        <Box padding={2} pt={0} gap={2}>
          <Dropdown
            label="เหตุผล"
            required
            options={reasonRejectOptions}
            getOptionLabel={(option: ReasonRejectOption) => option.name}
            fullWidth
            placeholder="กรุณาเลือกเหตุผล"
            crud="reasonReject"
          />

          <CustomTextArea
            labelName="รายละเอียดเพิ่มเติม"
            fieldName="rejectDetail"
            placeholder="กรุณาเลือกเหตุผล"
          />

          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}
            paddingY={1}
          >
            <Button variant="outlined" onClick={closeModal}>
              ยกเลิก
            </Button>
            <LoadingButton
              variant="contained"
              disabled={isEmpty(formData?.reasonReject) || isLoading}
              onClick={handleFullFormReject}
              loadingPosition="end"
              endIcon={<></>}
              loading={isLoading}
            >
              ยืนยัน
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>

      <ShowFile />
      <ShowFixedFileBox />
    </>
  )
}
