import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { dispatch, useSelector } from '../../../redux/store'
import { getHistoryAudit } from './FormRequestDecision/handler/getHistoryAudit'
import { selectInitialProfile } from '../../../redux/selector'
import Box from '@mui/material/Box'
import { useHistoryAudit } from './handler/useHistoryAudit'
import { IconButton } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { closeDrawer } from '../../../components/CustomDrawer/slices'

import HistoryTable, {
  DataTableCol,
} from '../../../components/Table/TableHistory'
import { DateFormat } from '../../../constants/dateFormat'
import { displayDateTime } from '../../../utils/convertFormatDateTime'
import { TabApplicationReview } from './model/tab'
import _ from 'lodash'
import { setLineClamp } from '../../../utils/lineClamp'
export const HistoryAudit = () => {
  const initProfile = useSelector(selectInitialProfile)
  const fullFormUuid = String(initProfile?.fullformUuid)
  const [isLoading, setIsLoading] = useState(false)
  const historyAudit = useHistoryAudit()
  useEffect(() => {
    const _init = async () => {
      setIsLoading(true)
      await dispatch(getHistoryAudit(fullFormUuid))
      setIsLoading(false)
    }
    _init()
  }, [])

  const headers: DataTableCol[] = [
    {
      headerName: 'ขั้นตอน',
      width: '20%',
      field: 'sectionName',

      renderCell: (row: any) => (
        <>
          <Typography sx={setLineClamp(1)} variant="body2">
            {_.get(TabApplicationReview, `${row.sectionName}`)}
          </Typography>
        </>
      ),
    },
    {
      headerName: 'เหตุผล',
      width: '20%',
      field: 'reason',
    },
    {
      headerName: 'รายละเอียด',
      width: '30%',
      field: 'detail',
      tooltip: true,
    },
    {
      headerName: 'ผู้ตรวจ',
      width: '15%',
      field: 'operator',
    },
    {
      headerName: 'วันที่ทำรายการ',
      width: '20%',
      field: 'currectData',
      renderCell: (row: any) => (
        <>{displayDateTime(row.currectData, DateFormat.DDMMYYYYHHmm)}</>
      ),
    },
  ]

  return (
    <Box sx={{ p: 3, width: '100%', maxWidth: '1100px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h5">ประวัติการขอแก้ไขเอกสาร</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => dispatch(closeDrawer())}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>
      </Box>

      <HistoryTable
        isLoading={isLoading}
        headers={headers}
        rows={historyAudit.items}
      />
    </Box>
  )
}
