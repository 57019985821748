import useTheme from '@mui/material/styles/useTheme'
import { ListProps } from '@mui/material/List'
import ListItem, { ListItemProps } from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import styled from '@mui/material/styles/styled'
//
import { ListStyle } from '../styled'

type IListItemProps = ListItemProps & { hasSubheader: boolean }

const ListItemStyle = styled(ListItem, {
  shouldForwardProp: (props) => props !== 'hasSubheader',
})<IListItemProps>(({ theme, hasSubheader }) => ({
  justifyContent: 'space-between',
  ...(hasSubheader && {
    '&.MuiListItem-root:first-of-type': {
      marginTop: theme.spacing(2),
    },
  }),
}))

type Props = ListProps & {
  listData: { label: string; value: string | number }[]
}

export default function CustomListItem({
  listData,
  ...other
}: Readonly<Props>) {
  const theme = useTheme()

  return (
    <ListStyle
      sx={{ width: '100%', bgcolor: theme.palette.common.white }}
      {...other}
    >
      {listData.map((item) => (
        <ListItemStyle
          key={`${item.label}-${item.value}`}
          divider
          hasSubheader={!!other.subheader}
        >
          <ListItemText primary={item.label} sx={{ flex: 1 }} />
          <ListItemText primary={item.value} sx={{ flex: 1, pl: 2 }} />
        </ListItemStyle>
      ))}
    </ListStyle>
  )
}
