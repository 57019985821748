import { store } from '../../../../../../redux/store'
import { getFormatDate } from '../../../../../../utils/getFormatDate'
import { formatDate } from '../constants/formatDate'
import { ProcessResultApprovalStatus } from '../../../../../../constants/status'
import { LOGIN_STATUS, USER_STATUS, CHANNEL } from '../model/status'

export function mutateFilterSateToProp() {
  const { filterState }: any = store.getState().table
  const status = []
  const loginStatus = []
  const userStatus = []
  const channel = []
  if (filterState?.prospectStatusChecked) {
    if (filterState?.prospectStatus?.waitingForReAssignChecked) {
      status.push(ProcessResultApprovalStatus.DL_SUCCESSFULLY_SENT_ASSIGN_RULE)
    }
    if (filterState?.prospectStatus?.submittiedShortFormChecked) {
      status.push(ProcessResultApprovalStatus.DL_ASSIGNED_MA)
    }
    if (filterState?.prospectStatus?.submittiedShortFormInactiveChecked) {
      status.push(ProcessResultApprovalStatus.DL_SUBMITTED_SHORT_FORM)
    }
    if (filterState?.prospectStatus?.managerAcceptedInterviewChecked) {
      status.push(ProcessResultApprovalStatus.DL_SUBMITTED_SHORT_FORM)
    }
    if (status.length === 0) {
      status.push(
        ProcessResultApprovalStatus.DL_SUCCESSFULLY_SENT_ASSIGN_RULE,
        ProcessResultApprovalStatus.DL_ASSIGNED_MA,
        ProcessResultApprovalStatus.DL_SUBMITTED_SHORT_FORM
      )
    }
  } else {
    status.length = 0
  }

  if (filterState?.loginStatusChecked) {
    if (filterState?.loginStatus.statusActiveChecked) {
      loginStatus.push(LOGIN_STATUS.ACTIVE)
    }
    if (filterState?.loginStatus.statusInactiveChecked) {
      loginStatus.push(LOGIN_STATUS.INACTIVE)
    }
    if (loginStatus.length === 0) {
      loginStatus.push(LOGIN_STATUS.ACTIVE, LOGIN_STATUS.INACTIVE)
    }
  } else {
    loginStatus.length = 0
  }

  if (filterState?.userStatusChecked) {
    if (filterState?.userStatus.statusActiveChecked) {
      userStatus.push(USER_STATUS.ACTIVE)
    }
    if (filterState?.userStatus.statusInactiveChecked) {
      userStatus.push(USER_STATUS.INACTIVE)
    }
    if (filterState?.userStatus.statusDeletedChecked) {
      userStatus.push(USER_STATUS.DELETED)
    }
    if (userStatus.length === 0) {
      userStatus.push(
        USER_STATUS.ACTIVE,
        USER_STATUS.INACTIVE,
        USER_STATUS.DELETED
      )
    }
  } else {
    userStatus.length = 0
  }

  if (filterState?.channelChecked) {
    if (filterState?.channel.directChecked) {
      channel.push(CHANNEL.DIRECT)
    }
    if (filterState?.channel.referralChecked) {
      channel.push(CHANNEL.REFERRAL)
    }
    if (channel.length === 0) {
      channel.push(CHANNEL.DIRECT, CHANNEL.REFERRAL)
    }
  }

  return {
    name: filterState.nameChecked ? filterState.name : '',
    managerCode: filterState.managerCodeChecked ? filterState.managerCode : '',
    managerName: filterState.managerNameChecked ? filterState.managerName : '',
    channel,
    startDate: filterState.dateChecked
      ? getFormatDate(filterState.startDate, formatDate)
      : '',
    endDate: filterState.dateChecked
      ? getFormatDate(filterState.endDate, formatDate)
      : '',
    status: status,
    loginStatus,
    userStatus,
  }
}
