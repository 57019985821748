import { type Dispatch, type SetStateAction } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
// redux
import { useSelector } from '../../../../redux/store'
import { selectProfileDetails } from '../../../../redux/selector'
//
import ListItem from './ListItem'
import HeaderActions from './HeaderActions'
import { CO_BORROWER, TAX_MARITAL_STATUS } from '../../../../constants/status'
import {
  getLabelFullFormArrText,
  FULLFORM,
} from '../../../../constants/dc-admin'

type Props = {
  setTab: Dispatch<SetStateAction<number>>
}

export default function TaxDeductionStep({ setTab }: Readonly<Props>) {
  const profileDetails = useSelector(selectProfileDetails)

  const totalChildrenNum =
    Number(profileDetails?.tax?.firstChildrenNum) +
    Number(profileDetails?.tax?.otherChildrenNum)

  if (!profileDetails) {
    return null
  }

  return (
    <Stack spacing={3}>
      {/* HEADER ACTIONS SECTION */}
      <HeaderActions titleStep="Tax-Deduction" setTab={setTab} />

      {/* LIST ITEM SECTION */}
      <Box>
        {/* LIST ITEM HEADER */}
        <Typography variant="body1b">รายการลดหย่อน</Typography>

        <Stack
          minHeight={56}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="tableHeader" flex={1} pl={2}>
            หัวข้อ
          </Typography>
          <Typography variant="tableHeader" flex={1}>
            ข้อมูลที่กรอก
          </Typography>
        </Stack>

        {/* LIST ITEM CONTENT */}
        <ListItem
          listData={[
            {
              label: 'ฐานะการสมรส',
              value:
                TAX_MARITAL_STATUS[profileDetails.tax.taxMaritalStatus] ?? '-',
            },
            {
              label: 'มีบุตรหรือบุตรบุญธรรมที่มีสิทธินำมาหักค่าลดหย่อน',
              value: `${totalChildrenNum > 0 ? 'มี' : 'ไม่มี'}`,
            },
            {
              label: 'จํานวนบุตรคนแรกและเกิดก่อนปี 2561 รวม',
              value:
                Number(profileDetails?.tax?.firstChildrenNum) > 0
                  ? profileDetails.tax.firstChildrenNum
                  : '-',
            },
            {
              label:
                'จํานวนบุตรตั้งแต่คนที่2 เป็นต้นไปที่เกิดในหรือหลังปี พ.ศ.2561',
              value:
                Number(profileDetails?.tax?.otherChildrenNum) > 0
                  ? profileDetails.tax.otherChildrenNum
                  : '-',
            },
            {
              label: 'รวมจำนวน คน',
              value: totalChildrenNum > 0 ? totalChildrenNum : '-',
            },
            {
              label:
                'เบี้ยประกันชีวิตที่จ่ายในปี ภาษี (กรมธรรม์มีกําหนดระยะเวลาตั้งแต่ 10 ปี ขึ้นไป)',
              value: profileDetails.tax.lifeInsurancePremium ?? '-',
            },
            {
              label:
                'เบี้ยประกันสุขภาพของผู้มีเงินได้ (ตามที่จ่ายจริง แต่ไม่เกิน 15,000 บาท และเมื่อรวมกับเบี้ยประกันชีวิต ต้องไม่เกิน 100,000 บาท)',
              value: profileDetails.tax.healthInsurancePremium ?? '-',
            },
            {
              label:
                'ดอกเบี้ยเงินกู้ยืมเพื่อซื้อ เช่าซื้อ หรือสร้างอาคารที่อยู่อาศัย (ตามจริงแต่ไม่เกิน 100,000 บาท)',
              value: profileDetails.tax.interestHomeLoans ?? '-',
            },
            {
              label: 'มีผู้กู้ร่วมหรือไม่',
              value:
                getLabelFullFormArrText(
                  FULLFORM.YesNo,
                  profileDetails?.tax?.coBorrower
                ) ?? '-',
            },
            {
              label: 'รูปแบบผู้กู้ร่วม',
              value: CO_BORROWER[profileDetails.tax.coBorrower] ?? '-',
            },
            {
              label: 'รวมจํานวนผู้กู้ร่วมทั้งหมด (รวมตัวผู้มีเงินได้เองด้วย)',
              value:
                Number(profileDetails?.tax?.totalCoBorrower) > 0
                  ? profileDetails?.tax?.totalCoBorrower
                  : '-',
            },
            {
              label: 'ค่าเลี้ยงดูบิดา-มารดา (หักได้คนละ 30,000 บาท)',
              value: '',
            },
            {
              label: 'บิดา ของผู้มีเงินได้ ',
              value: profileDetails.tax.fatherSupportAmount ?? '-',
            },
            {
              label: 'มารดา ของผู้มีเงินได้ ',

              value: profileDetails.tax.motherSupportAmount ?? '-',
            },
            {
              label: 'บิดา ของคู่สมรส',
              value: profileDetails.tax.spouseFatherSupportAmount ?? '-',
            },
            {
              label: 'มารดา ของคู่สมรส',
              value: profileDetails.tax.spouseMotherSupportAmount ?? '-',
            },
            {
              label:
                'เบี้ยประกันสุขภาพของบิดา-มารดา (ตามจำนวนที่จ่ายจริงแต่ไม่เกิน 15,000 บาท)',
              value: profileDetails.tax.rmfInvestmentAmount ?? '-',
            },
            {
              label:
                'ค่าซื้อกองทุนรวมเพื่อการออม (SSF) (ลดหย่อนภาษีสูงสุด 30% ของเงินได้พึงประเมินแต่ไม่เกิน 200,000 บาท)',
              value: profileDetails.tax.ssfInvestmentAmount ?? '-',
            },
          ]}
        />
      </Box>
    </Stack>
  )
}
