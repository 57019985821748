import InputLabel, { type InputLabelProps } from '@mui/material/InputLabel'
import _ from 'lodash'
import OutlinedInput, {
  type OutlinedInputProps,
} from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import React, { useCallback } from 'react'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { useDispatch } from 'react-redux'
import { createDebouncedSetField } from '../../CRUD/handler/createDebouncedSetField'
import { useInputField } from '../../CRUD/handler/useInputField'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { AppDispatch } from '../../../redux/store'
import { CustomInput } from '../types/propsInputs'

type ICustomTextfieldProps = {
  inputLabelProps?: InputLabelProps
}

type CustomTextfieldProps = CustomInput &
  OutlinedInputProps &
  ICustomTextfieldProps

const CustomTextfield = (props: CustomTextfieldProps) => {
  const dispatch: AppDispatch = useDispatch()

  const {
    boxSx,
    sx,
    type,
    label,
    disabled,
    onClick,
    onBlur,
    onChange,
    key,
    defaultValue,
    inputProps,
    rows,
    maxRows,
    fullWidth,
    sxTextError,
    required,
    valueType,
    ...other
  } = props

  const maxLength = _.get(props, 'maxLength', 100)
  const showMaxLength = _.get(props, 'showMaxLength', 0)

  const isCrud = !!(!_.isEmpty(props.crud) && !_.isUndefined(props.crud))
  const name = isCrud ? _.get(props, 'crud', '') : _.get(props, 'name', '')
  const id = !_.isEmpty(props.id) && !_.isUndefined(props.id) ? props.id : name

  const { value, message, isError } = useInputField(name)

  const [showPassword, setShowPassword] = React.useState(false)
  const [inputValue, setInputValue] = React.useState(value)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const inputPassword = () => {
    if (type !== 'password') return
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    )
  }
  const debouncedSetField = useCallback(
    createDebouncedSetField(dispatch, name),
    [dispatch]
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.currentTarget.value

    if (valueType === 'number' || valueType === 'mobile') {
      val = val.replace(/[^0-9]/g, '')
    }

    if (val.length <= maxLength) {
      setInputValue(val)
      debouncedSetField(val)
    }
    /*
    if (maxLength > 0 && _.size(val) > maxLength) {
      dispatch(
        setAddFormErrors({
          path: name,
          message: `${_.size(val)}/${maxLength}`,
        })
      )
    } else {
      dispatch(
        setRemoveFieldError({
          path: name,
        })
      )
    }*/
  }
  React.useEffect(() => {
    console.log(`${props.crud}>>${value}`)
    setInputValue(value)
  }, [value])

  const _inputValue = inputValue ?? ''
  const xValue = isCrud ? _inputValue : props.value

  const getRequired = () => {
    if (!required) return
    return (
      <Typography sx={{ ml: 1, pt: '5px' }} variant="body2" color={'error'}>
        *
      </Typography>
    )
  }

  return (
    <Box sx={{ mb: 2, ...boxSx }}>
      <InputLabel sx={{ display: 'flex' }} htmlFor={name}>
        <Typography variant="body2">{label}</Typography> {getRequired()}
      </InputLabel>
      <OutlinedInput
        label={''}
        type={showPassword ? 'text' : type}
        sx={{ p: 0, pr: 1.5, backgroundColor: 'white.main', ...sx }}
        id={id}
        name={name}
        error={isError}
        disabled={disabled}
        value={xValue}
        onClick={onClick}
        onBlur={onBlur}
        onChange={isCrud ? handleChange : onChange}
        key={key}
        defaultValue={defaultValue}
        inputProps={inputProps}
        rows={rows}
        maxRows={maxRows}
        fullWidth={fullWidth}
        endAdornment={inputPassword()}
        {...other}
      />
      {isError && message !== '' && (
        <Typography sx={{ ...sxTextError }} variant="body2" color="error">
          {message}
        </Typography>
      )}
      {showMaxLength > 0 && (
        <Typography
          sx={{
            textAlign: 'right',
            color: _.size(value) > showMaxLength ? 'error.main' : 'unset',
          }}
          variant="body2"
        >
          {`${_.size(value)}/${showMaxLength}`}
        </Typography>
      )}
    </Box>
  )
}
export default CustomTextfield
