import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import styled from '@mui/material/styles/styled'
import { type Theme } from '@mui/material'
// redux
import {
  selectCheckListStatus,
  selectInitialProfile,
  selectMemoList,
  selectProfileDetails,
} from '../../../redux/selector'
import { useSelector } from '../../../redux/store'
import { applicationStatusStep } from '../slices/applicant-details'
//
import {
  PersonalInfoStep,
  ProfileDetailStep,
  TaxDeductionStep,
  PoliciesStep,
  AttachDocumentsStep,
} from './step'
import { TabPanelProps } from './TabFeatures'
import { MemoListDrawer, RelatedDocsDrawer } from './Drawer'
import { TitleStepNumber } from './model/table'
import { tabNames } from './model/tab'
import { IS_CONSIDERATION_OPTIONS } from '../../../constants/status'
import _ from 'lodash'
import { MemoStatus } from '../../../constants/dc-admin/fullForm'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'
import useLocalStorage from '@rehooks/local-storage'
import { permissionItem } from '../../../constants/permission'

const TabsStyle = styled(Tabs)(({ theme }) => ({
  width: 275,

  '& .MuiTabs-scroller': {
    flexGrow: 0,
    borderRadius: '8px',
    border: `1px solid ${theme.palette.blue.blueBorder}`,
    boxShadow: `0px 12px 40px 0px ${theme.palette.blue.oceanBlue}1A`,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
  },

  '& .MuiTabs-indicator': {
    height: 'unset !important',
    width: 'unset',
  },
}))

const CtaWrapper = styled(Stack)(({ theme }) => ({
  flex: '1 1 100%',
  marginLeft: theme.spacing(-2), // * to adjust width exceed the container
  marginRight: theme.spacing(-3), // * to adjust width exceed the containerF
  position: 'sticky',
  bottom: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  padding: theme.spacing(2, 6, 2, 3),
  backgroundColor: theme.palette.common.white,
}))

export default function ApplicationReview() {
  const [tab, setTab] = useState(0)

  const theme = useTheme()

  const checkListStatus = useSelector(selectCheckListStatus)
  const initProfile = useSelector(selectInitialProfile)
  const profileDetails = useSelector(selectProfileDetails)
  const { isLoading } = useSelector((state) => state.applicantDetails)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  if (!profileDetails || isLoading) {
    return (
      <Stack width={1} alignItems="center" justifyContent="center">
        <CircularProgress size={30} />
      </Stack>
    )
  }

  return (
    <Stack direction="row" flexWrap="wrap" gap={3}>
      {/* TAB APPLICATION REVIEW STEPS */}
      <Stack>
        <TabsStyle
          orientation="vertical"
          variant="scrollable"
          value={tab}
          onChange={handleChange}
          aria-label="Tab application review"
        >
          <Tab
            sx={{
              px: 0,
              borderBottom: getActiveTab(tab, 0, theme),
            }}
            label={
              <TabItem
                sectionId="Personal"
                active={tab === 0}
                title="ประวัติส่วนตัว"
                subTitle="ขั้นตอนที่ 1"
                isChecking={
                  profileDetails?.consideration[tabNames.PERSONAL] ===
                  IS_CONSIDERATION_OPTIONS.Completed
                }
              />
            }
          />
          <Tab
            sx={{ px: 0, borderBottom: getActiveTab(tab, 1, theme) }}
            label={
              <TabItem
                sectionId="Education-Work"
                active={tab === 1}
                title={
                  'ประวัติการศึกษา / ประสบการณ์\nการทำงาน / รายละเอียดอื่นๆ'
                }
                subTitle="ขั้นตอนที่ 2"
                isChecking={
                  profileDetails?.consideration[tabNames.EDUCATIONWORK] ===
                  IS_CONSIDERATION_OPTIONS.Completed
                }
              />
            }
          />
          <Tab
            sx={{ px: 0, borderBottom: getActiveTab(tab, 2, theme) }}
            label={
              <TabItem
                sectionId="Tax-Deduction"
                active={tab === 2}
                title={'รายการลดหย่อนเพื่อการคำนวณ\nภาษีเงินได้บุคคลธรรมดา'}
                subTitle="ขั้นตอนที่ 3"
                isChecking={
                  profileDetails?.consideration[tabNames.TAXDEDUCTION] ===
                  IS_CONSIDERATION_OPTIONS.Completed
                }
              />
            }
          />
          <Tab
            sx={{ px: 0, borderBottom: getActiveTab(tab, 3, theme) }}
            label={
              <TabItem
                sectionId="Policy"
                active={tab === 3}
                title="นโยบาย"
                subTitle="ขั้นตอนที่ 4"
                isChecking={
                  profileDetails?.consideration[tabNames.POLICY] ===
                  IS_CONSIDERATION_OPTIONS.Completed
                }
              />
            }
          />
          <Tab
            sx={{ px: 0, borderBottom: getActiveTab(tab, 4, theme) }}
            label={
              <TabItem
                sectionId="Attachment"
                active={tab === 4}
                title="แนบเอกสาร"
                subTitle="ขั้นตอนที่ 5"
                isChecking={
                  profileDetails?.consideration[tabNames.ATTACHMENT] ===
                  IS_CONSIDERATION_OPTIONS.Completed
                }
              />
            }
          />
        </TabsStyle>
      </Stack>

      {/* PERSONAL INFO STEP */}
      <CustomTabPanel value={tab} index={0}>
        <PersonalInfoStep setTab={setTab} />
      </CustomTabPanel>

      {/* PROFILE DETAILS STEP */}
      <CustomTabPanel value={tab} index={1}>
        <ProfileDetailStep setTab={setTab} />
      </CustomTabPanel>

      {/* TAX DEDUCTION STEP */}
      <CustomTabPanel value={tab} index={2}>
        <TaxDeductionStep setTab={setTab} />
      </CustomTabPanel>

      {/* POLICIES STEP */}
      <CustomTabPanel value={tab} index={3}>
        <PoliciesStep setTab={setTab} />
      </CustomTabPanel>

      {/* ATTACH DOCUMENTS STEP */}
      <CustomTabPanel value={tab} index={4}>
        <AttachDocumentsStep setTab={setTab} />
      </CustomTabPanel>

      {/* CALL TO ACTION (CTA) SECTION */}
      {/* IN-PROGRESS & HAS ACCEPTING WORK */}
      {checkListStatus !== applicationStatusStep.DS_REVIEWED &&
        !_.isNull(initProfile?.contractor) && (
          <CallToAction currApplicationTab={tab + 1} />
        )}
    </Stack>
  )
}

type Props = {
  sectionId: string
  active: boolean
  title: string
  subTitle: string
  isChecking: boolean
}

function TabItem({
  active,
  title,
  subTitle,
  isChecking,
  sectionId,
}: Readonly<Props>) {
  const theme = useTheme()
  const profileDetails = useSelector(selectProfileDetails)
  const memoList = useSelector(selectMemoList)

  const dcMemo = profileDetails?.dcMemo ?? []

  const isWarning = _.find(dcMemo, {
    reasonSection: sectionId,
    status: MemoStatus.INPROGRESS,
  })
  const isErr = _.find(memoList, {
    titleStep: sectionId,
  })
  const isError =
    _.find(
      dcMemo.filter((e) => e.status === MemoStatus.ACTIVE),
      { reasonSection: sectionId }
    ) || isErr
  return (
    <Stack width={1} alignSelf="flex-start" spacing={4} textAlign="left">
      <Typography
        variant="body3"
        sx={{ color: isColorChecking(isChecking, theme, isError, isWarning) }}
      >
        {subTitle}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mt="4px !important"
      >
        <Typography
          variant={active ? 'body1b' : 'subtitle1'}
          sx={{ color: isColorChecking(isChecking, theme, isError, isWarning) }}
          whiteSpace="pre-line"
          textAlign="left"
        >
          {title}
        </Typography>
        {isError && <ErrorIcon fontSize="medium" color={'error'} />}
        {isWarning && !isError && (
          <WarningIcon fontSize="medium" color={'warning'} />
        )}
        {!isWarning && !isError && (
          <CheckCircleIcon
            fontSize="medium"
            sx={{
              color: isColorChecking(isChecking, theme, isError, isWarning),
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

function CustomTabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props

  const theme = useTheme()

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-form-${index}`}
      aria-labelledby={`tab-form-${index}`}
      style={{ flex: 1 }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            bgcolor: theme.palette.common.white,
            border: `1px solid ${theme.palette.blue.blueBorder}`,
            borderRadius: '8px',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  )
}

function CallToAction({
  currApplicationTab,
}: Readonly<{ currApplicationTab: number }>) {
  const [openDrawer, setOpenDrawer] = useState<Record<string, boolean>>({
    memo: false,
    relatedDocs: false,
  })

  const [user] = useLocalStorage('user')
  const uniqPermissions = _.get(user, 'uniqPermissions', [])
  const isPermission = _.includes(uniqPermissions, permissionItem.DC_ADMIN)

  const memoList = useSelector(selectMemoList)

  const toggleDrawer = (newValue: boolean, name: string) => () => {
    setOpenDrawer({
      [name]: newValue,
    })
  }

  return (
    <CtaWrapper>
      {currApplicationTab !== TitleStepNumber.Attachment && (
        <Chip
          label={<Typography variant="chip">เอกสารที่เกี่ยวข้อง</Typography>}
          icon={<FolderOpenOutlinedIcon color="primary" />}
          onClick={toggleDrawer(true, 'relatedDocs')}
          variant="outlined"
          sx={{ maxWidth: 'fit-content', paddingLeft: '0.25rem' }}
        />
      )}

      {isPermission && (
        <Stack direction="row" alignItems="center" gap={2} marginLeft="auto">
          <ContentPasteOutlinedIcon color="primary" />
          <Stack direction="row" alignItems="center" spacing={1} mr={1}>
            <Typography variant="buttonl">รายการขอแก้ไขเอกสาร</Typography>
            <Typography variant="buttonl" color="primary">
              {memoList.length}
            </Typography>
            <Typography variant="buttonl">รายการ</Typography>
          </Stack>
          <Button
            variant="contained"
            sx={{ width: 112 }}
            onClick={toggleDrawer(true, 'memo')}
          >
            จัดการ
          </Button>
        </Stack>
      )}

      {/* DRAWER */}
      <MemoListDrawer open={openDrawer['memo']} toggleDrawer={toggleDrawer} />
      <RelatedDocsDrawer
        open={openDrawer['relatedDocs']}
        toggleDrawer={toggleDrawer}
      />
    </CtaWrapper>
  )
}

function getActiveTab(tab: number, currentIndex: number, theme: Theme) {
  return tab === currentIndex
    ? `4px solid ${theme.palette.primary.main}`
    : `1px solid ${theme.palette.grey['300']}`
}

function isColorChecking(
  isActive: boolean,
  theme: Theme,
  isError = false,
  isWarning = false
) {
  if (isError) return theme.palette.text.error
  if (isWarning) return theme.palette.text.warning
  return isActive ? theme.palette.primary.main : theme.palette.text.secondary
}
