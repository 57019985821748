import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, NotificationState } from './model'

const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setResult: (state: NotificationState, action: PayloadAction<any>) => {
      state.result = action.payload.result
      state.page = action.payload.page
      state.limit = action.payload.limit
      state.countTotal = action.payload.countTotal
    },
    setCountNotification: (
      state: NotificationState,
      action: PayloadAction<number>
    ) => {
      state.countNotification = action.payload
    },
    setInitialListPage: (state: NotificationState) => {
      state.listPage = initialState.listPage
    },
    setListPageType: (state: NotificationState, action: PayloadAction<any>) => {
      state.listPage.type = action.payload
    },
    setListPagePage: (state: NotificationState, action: PayloadAction<any>) => {
      state.listPage.page = action.payload
    },
    setListPageLimit: (
      state: NotificationState,
      action: PayloadAction<any>
    ) => {
      state.listPage.limit = action.payload
    },
    setListPageLoading: (
      state: NotificationState,
      action: PayloadAction<any>
    ) => {
      state.listPage.isLoading = action.payload
    },
    setListPageResult: (
      state: NotificationState,
      action: PayloadAction<any>
    ) => {
      state.listPage.countTotal = action.payload.countTotal
      const newResult = action.payload.result
      if (state.listPage.page > 1) {
        state.listPage.result = [...state.listPage.result, newResult]
      } else {
        state.listPage.result = newResult
      }
      state.listPage.hasMore =
        state.listPage.countTotal > state.listPage.result.length
    },
  },
})
export const {
  setResult,
  setCountNotification,
  setInitialListPage,
  setListPageType,
  setListPageLimit,
  setListPagePage,
  setListPageLoading,
  setListPageResult,
} = NotificationSlice.actions

export default NotificationSlice.reducer
