import isEmpty from 'lodash/isEmpty'
import Modal from 'react-modal'
import { type MouseEvent, useState } from 'react'
import AccordionActions from '@mui/material/AccordionActions'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import useTheme from '@mui/material/styles/useTheme'
import styled from '@mui/material/styles/styled'
import { dispatch, useSelector } from '../../../../redux/store'
import {
  selectInitialProfile,
  selectProgressStatus,
  selectCrudFormData,
} from '../../../../redux/selector'
import { applicationStatusStep } from '../../slices/applicant-details'
import UploadSingleFile from '../../../../components/Upload/UploadSingleFile'
import BasicUpload from '../../../../components/Upload/BasicUpload'
import Image from '../../../../components/Image/Image'
import CustomRadioGroup from '../../../../components/Input/CustomRadioGroup'
import { handleUploadFile } from '../../../../utils/dispatchError'

import {
  CardApproveStyle,
  CardStyle,
  CardContentStyle,
  AccordionStyle,
  AccordionSummaryWrapper,
} from '../styled'
import { considerationOptions } from '../model/consideration'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { LoadingButton } from '@mui/lab'
import { HistoryDecision } from '../FormRequestDecision/HistoryDecision'
import { openCustomDialog } from '../../../../components/CustomDialog/slices'
import { DIALOG_TYPE_STRING } from '../../../../components/dialog/constants'
import { displayDateTime } from '../../../../utils/convertFormatDateTime'

Modal.setAppElement('#root')

const RadioGroupWrapper = styled(Box)(({ theme }) => ({
  flex: 1,

  '& .MuiFormGroup-root': {
    width: '40%',
    justifyContent: 'space-between',
  },

  '& .MuiFormControl-root': {
    width: '100%',
  },

  '& .MuiButtonBase-root.MuiRadio-root': {
    padding: 0,
    marginRight: theme.spacing(1.5),
  },
}))

export default function AwaitingReviewOrFollowUpStatus() {
  const initProfile = useSelector(selectInitialProfile)
  const applicationStatus = useSelector(selectProgressStatus)

  const { data, error } = useSelector((state) => state.upload)
  const formData = useSelector(selectCrudFormData)

  const { isLoading } = useSelector((state) => state.applicantDetails)

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [dialogName, setDialogName] = useState('')

  const theme = useTheme()

  const openModal = () => {
    setIsOpenModal(true)

    document.body.style.overflow = 'hidden'
  }

  const closeModal = () => {
    setIsOpenModal(false)

    document.body.style.overflow = 'auto'

    setDialogName('')
  }

  const onOpenDialog = (event: MouseEvent<HTMLButtonElement>) => {
    setDialogName(event.currentTarget.name)

    event.stopPropagation() // Prevent event from bubbling to the parent
  }

  const onUpdateDecision = () => {
    console.log('submit update decision => ')
  }

  const getUploadFile = () => {
    console.log('getUploadFile => ')
  }

  return (
    <>
      <CardStyle>
        <CardContentStyle>
          <Stack direction="row" alignItems="center" gap={2}>
            <Image
              src="/images/illustrate_awaiting_ccm_check.svg"
              alt="Awaiting review or follow up process"
              effect="opacity"
              sizes="56"
              sx={{
                '& .MuiBox-root': {
                  verticalAlign: 'middle',
                  objectFit: 'contain',
                },
              }}
            />
            <Stack flex={1} justifyContent="center" gap={0.5}>
              <Typography variant="h5" color="primary.main">
                {applicationStatus && applicationStatusStep[applicationStatus]}
              </Typography>
              <Typography variant="body2" color="text.gray">
                วันที่: {displayDateTime(initProfile?.statsuUpdateDate)}
              </Typography>
            </Stack>

            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                dispatch(
                  openCustomDialog({
                    type: DIALOG_TYPE_STRING.CUSTOM,
                    content: <HistoryDecision isEdit={true} />,
                    boxSx: { pr: 0, pe: 0 },
                  })
                )
              }}
            >
              <Typography variant="buttons" color="primary">
                ผลการพิจารณา
              </Typography>
            </Button>
          </Stack>
        </CardContentStyle>
      </CardStyle>

      <Modal
        isOpen={isOpenModal}
        onRequestClose={closeModal}
        contentLabel="Add awaiting review or follow up status modal"
        style={{
          overlay: {
            backgroundColor: `${theme.palette.common.black}BF`,
            zIndex: 1200, // For ensuring overlay is above other elements
          },
          content: {
            maxWidth: '678px',
            maxHeight: '80%',
            width: '100%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '1rem',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
            boxShadow: `0px 9px 46px 8px ${theme.palette.common.black}1F,
                          0px 24px 38px 3px ${theme.palette.common.black}24,
                          0px 11px 15px -7px ${theme.palette.common.black}33`,
          },
        }}
      >
        {!dialogName ? ( // * RENDER REVIEW AND FOLLOW UP DIALOG
          <>
            <Stack gap={2}>
              <Typography variant="h6">รอพิจารณา / ติดตามผล</Typography>

              {/* CCM CARD ITEM */}
              <CardApproveStyle>
                <Box flex={1}>
                  <Typography variant="body1b" color="primary">
                    CCM
                  </Typography>
                </Box>
                <Box flex={1}>
                  <Typography variant="body2" color="text.gray">
                    สถานะ
                  </Typography>
                  <Typography variant="body1b">ตรวจสอบแล้ว</Typography>
                </Box>
              </CardApproveStyle>

              {/* RAM CARD ITEM */}
              <AccordionStyle>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="disabled" />}
                  aria-controls="ram-panel-content"
                  id="ram-header"
                >
                  <Stack direction="column" flex={1} gap={2}>
                    <AccordionSummaryWrapper>
                      <Stack justifyContent="center" flex={1}>
                        <Typography variant="body1b" color="primary">
                          RAM
                        </Typography>
                      </Stack>

                      <Box flex={1} ml={3}>
                        <Typography variant="body2" color="text.gray">
                          สถานะ
                        </Typography>
                        <Typography variant="body1b">กำลังพิจารณา</Typography>
                      </Box>
                    </AccordionSummaryWrapper>

                    <Divider className="MuiAccordionSummary-divider" />

                    <AccordionSummaryWrapper
                      className="MuiAccordionSummary-actions"
                      sx={{ justifyContent: 'flex-end' }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={onOpenDialog}
                          name="considerationRAM"
                        >
                          ผลพิจารณา
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={onOpenDialog}
                          name="confirmResultRAM"
                        >
                          ยึนยันผล
                        </Button>
                      </Stack>
                    </AccordionSummaryWrapper>
                  </Stack>
                </AccordionSummary>

                <Divider
                  sx={{
                    marginX: theme.spacing(2),
                  }}
                />

                <AccordionDetails>
                  <Stack direction="row" justifyContent="space-between" gap={3}>
                    {/* NAME ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        ชื่อ
                      </Typography>
                      <Typography variant="body1b">วรรณภา ศิลาดี</Typography>
                    </Box>

                    {/* MOBILE NUMBER ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        เบอร์โทรศัพท์
                      </Typography>
                      <Typography variant="body1b">0895452165</Typography>
                    </Box>

                    {/* EMAIL ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        อีเมล
                      </Typography>
                      <Typography variant="body1b">ram@email.com</Typography>
                    </Box>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between" gap={3}>
                    {/* NAME ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        เลขา
                      </Typography>
                      <Typography variant="body1b">เอมอร แก่นแก้ว</Typography>
                    </Box>

                    {/* MOBILE NUMBER ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        เบอร์โทรศัพท์
                      </Typography>
                      <Typography variant="body1b">0845545245</Typography>
                    </Box>

                    {/* EMAIL ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        อีเมล
                      </Typography>
                      <Typography variant="body1b">as_ram@email.com</Typography>
                    </Box>
                  </Stack>
                  <Box flex={1}>
                    <Typography variant="body2" color="text.gray">
                      รายละเอียด
                    </Typography>
                    <Typography variant="body1b">
                      รบกวนพิจารณาด้วยครับ
                    </Typography>
                  </Box>

                  <Box flex={1}>
                    <Typography variant="body2" color="text.gray">
                      เอกสารแบบ
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                      <InsertPhotoIcon
                        sx={{ color: theme.palette.text.gray, fontSize: 32 }}
                      />
                      <Typography variant="body2">เอกสาร</Typography>
                    </Stack>
                  </Box>
                </AccordionDetails>

                <Divider
                  sx={{
                    marginX: theme.spacing(2),
                  }}
                />

                <AccordionActions sx={{ padding: theme.spacing(2) }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={onOpenDialog}
                    name="considerationRAM"
                  >
                    ผลพิจารณา
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={onOpenDialog}
                    name="confirmResultRAM"
                  >
                    ยึนยันผล
                  </Button>
                </AccordionActions>
              </AccordionStyle>

              {/* SRAM CARD ITEM */}
              <AccordionStyle>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="disabled" />}
                  aria-controls="ram-panel-content"
                  id="ram-header"
                >
                  <Stack direction="column" flex={1} gap={2}>
                    <AccordionSummaryWrapper>
                      <Stack justifyContent="center" flex={1}>
                        <Typography variant="body1b" color="primary">
                          SRAM
                        </Typography>
                      </Stack>

                      <Box flex={1} ml={3}>
                        <Typography variant="body2" color="text.gray">
                          สถานะ
                        </Typography>
                        <Typography variant="body1b">กำลังพิจารณา</Typography>
                      </Box>
                    </AccordionSummaryWrapper>

                    <Divider className="MuiAccordionSummary-divider" />

                    <AccordionSummaryWrapper
                      className="MuiAccordionSummary-actions"
                      sx={{ justifyContent: 'flex-end' }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={onOpenDialog}
                          name="considerationSRAM"
                        >
                          ผลพิจารณา
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={onOpenDialog}
                          name="confirmResultSRAM"
                        >
                          ยึนยันผล
                        </Button>
                      </Stack>
                    </AccordionSummaryWrapper>
                  </Stack>
                </AccordionSummary>

                <Divider
                  sx={{
                    marginX: theme.spacing(2),
                  }}
                />

                <AccordionDetails>
                  <Stack direction="row" justifyContent="space-between" gap={3}>
                    {/* NAME ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        ชื่อ
                      </Typography>
                      <Typography variant="body1b">วรรณภา ศิลาดี</Typography>
                    </Box>

                    {/* MOBILE NUMBER ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        เบอร์โทรศัพท์
                      </Typography>
                      <Typography variant="body1b">0895452165</Typography>
                    </Box>

                    {/* EMAIL ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        อีเมล
                      </Typography>
                      <Typography variant="body1b">ram@email.com</Typography>
                    </Box>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between" gap={3}>
                    {/* NAME ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        เลขา
                      </Typography>
                      <Typography variant="body1b">เอมอร แก่นแก้ว</Typography>
                    </Box>

                    {/* MOBILE NUMBER ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        เบอร์โทรศัพท์
                      </Typography>
                      <Typography variant="body1b">0845545245</Typography>
                    </Box>

                    {/* EMAIL ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        อีเมล
                      </Typography>
                      <Typography variant="body1b">as_ram@email.com</Typography>
                    </Box>
                  </Stack>
                  <Box flex={1}>
                    <Typography variant="body2" color="text.gray">
                      รายละเอียด
                    </Typography>
                    <Typography variant="body1b">
                      รบกวนพิจารณาด้วยครับ
                    </Typography>
                  </Box>

                  <Box flex={1}>
                    <Typography variant="body2" color="text.gray">
                      เอกสารแบบ
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                      <InsertPhotoIcon
                        sx={{ color: theme.palette.text.gray, fontSize: 32 }}
                      />
                      <Typography variant="body2">เอกสาร</Typography>
                    </Stack>
                  </Box>
                </AccordionDetails>

                <Divider
                  sx={{
                    marginX: theme.spacing(2),
                  }}
                />

                <AccordionActions sx={{ padding: theme.spacing(2) }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={onOpenDialog}
                    name="considerationSRAM"
                  >
                    ผลพิจารณา
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={onOpenDialog}
                    name="confirmResultSRAM"
                  >
                    ยึนยันผล
                  </Button>
                </AccordionActions>
              </AccordionStyle>

              {/* CADTO CARD ITEM */}
              <AccordionStyle>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="disabled" />}
                  aria-controls="ram-panel-content"
                  id="ram-header"
                >
                  <Stack direction="column" flex={1} gap={2}>
                    <AccordionSummaryWrapper>
                      <Stack justifyContent="center" flex={1}>
                        <Typography variant="body1b" color="primary">
                          CADTO
                        </Typography>
                      </Stack>

                      <Box flex={1} ml={3}>
                        <Typography variant="body2" color="text.gray">
                          สถานะ
                        </Typography>
                        <Typography variant="body1b">กำลังพิจารณา</Typography>
                      </Box>
                    </AccordionSummaryWrapper>

                    <Divider className="MuiAccordionSummary-divider" />

                    <AccordionSummaryWrapper
                      className="MuiAccordionSummary-actions"
                      sx={{ justifyContent: 'flex-end' }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={onOpenDialog}
                          name="considerationCADTO"
                        >
                          ผลพิจารณา
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={onOpenDialog}
                          name="confirmResultCADTO"
                        >
                          ยึนยันผล
                        </Button>
                      </Stack>
                    </AccordionSummaryWrapper>
                  </Stack>
                </AccordionSummary>

                <Divider
                  sx={{
                    marginX: theme.spacing(2),
                  }}
                />

                <AccordionDetails>
                  <Stack direction="row" justifyContent="space-between" gap={3}>
                    {/* NAME ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        ชื่อ
                      </Typography>
                      <Typography variant="body1b">วรรณภา ศิลาดี</Typography>
                    </Box>

                    {/* MOBILE NUMBER ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        เบอร์โทรศัพท์
                      </Typography>
                      <Typography variant="body1b">0895452165</Typography>
                    </Box>

                    {/* EMAIL ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        อีเมล
                      </Typography>
                      <Typography variant="body1b">ram@email.com</Typography>
                    </Box>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between" gap={3}>
                    {/* NAME ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        เลขา
                      </Typography>
                      <Typography variant="body1b">เอมอร แก่นแก้ว</Typography>
                    </Box>

                    {/* MOBILE NUMBER ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        เบอร์โทรศัพท์
                      </Typography>
                      <Typography variant="body1b">0845545245</Typography>
                    </Box>

                    {/* EMAIL ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        อีเมล
                      </Typography>
                      <Typography variant="body1b">as_ram@email.com</Typography>
                    </Box>
                  </Stack>
                  <Box flex={1}>
                    <Typography variant="body2" color="text.gray">
                      รายละเอียด
                    </Typography>
                    <Typography variant="body1b">
                      รบกวนพิจารณาด้วยครับ
                    </Typography>
                  </Box>

                  <Box flex={1}>
                    <Typography variant="body2" color="text.gray">
                      เอกสารแบบ
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                      <InsertPhotoIcon
                        sx={{ color: theme.palette.text.gray, fontSize: 32 }}
                      />
                      <Typography variant="body2">เอกสาร</Typography>
                    </Stack>
                  </Box>
                </AccordionDetails>

                <Divider
                  sx={{
                    marginX: theme.spacing(2),
                  }}
                />

                <AccordionActions sx={{ padding: theme.spacing(2) }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={onOpenDialog}
                    name="considerationCADTO"
                  >
                    ผลพิจารณา
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={onOpenDialog}
                    name="confirmResultCADTO"
                  >
                    ยึนยันผล
                  </Button>
                </AccordionActions>
              </AccordionStyle>

              {/* CDO CARD ITEM */}
              <AccordionStyle>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="disabled" />}
                  aria-controls="ram-panel-content"
                  id="ram-header"
                >
                  <Stack direction="column" flex={1} gap={2}>
                    <AccordionSummaryWrapper>
                      <Stack justifyContent="center" flex={1}>
                        <Typography variant="body1b" color="primary">
                          CDO
                        </Typography>
                      </Stack>

                      <Box flex={1} ml={3}>
                        <Typography variant="body2" color="text.gray">
                          สถานะ
                        </Typography>
                        <Typography variant="body1b">กำลังพิจารณา</Typography>
                      </Box>
                    </AccordionSummaryWrapper>

                    <Divider className="MuiAccordionSummary-divider" />

                    <AccordionSummaryWrapper
                      className="MuiAccordionSummary-actions"
                      sx={{ justifyContent: 'flex-end' }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={onOpenDialog}
                          name="considerationCDO"
                        >
                          ผลพิจารณา
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={onOpenDialog}
                          name="confirmResultCDO"
                        >
                          ยึนยันผล
                        </Button>
                      </Stack>
                    </AccordionSummaryWrapper>
                  </Stack>
                </AccordionSummary>

                <Divider
                  sx={{
                    marginX: theme.spacing(2),
                  }}
                />

                <AccordionDetails>
                  <Stack direction="row" justifyContent="space-between" gap={3}>
                    {/* NAME ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        ชื่อ
                      </Typography>
                      <Typography variant="body1b">วรรณภา ศิลาดี</Typography>
                    </Box>

                    {/* MOBILE NUMBER ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        เบอร์โทรศัพท์
                      </Typography>
                      <Typography variant="body1b">0895452165</Typography>
                    </Box>

                    {/* EMAIL ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        อีเมล
                      </Typography>
                      <Typography variant="body1b">ram@email.com</Typography>
                    </Box>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between" gap={3}>
                    {/* NAME ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        เลขา
                      </Typography>
                      <Typography variant="body1b">เอมอร แก่นแก้ว</Typography>
                    </Box>

                    {/* MOBILE NUMBER ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        เบอร์โทรศัพท์
                      </Typography>
                      <Typography variant="body1b">0845545245</Typography>
                    </Box>

                    {/* EMAIL ITEM */}
                    <Box flex={1}>
                      <Typography variant="body2" color="text.gray">
                        อีเมล
                      </Typography>
                      <Typography variant="body1b">as_ram@email.com</Typography>
                    </Box>
                  </Stack>
                  <Box flex={1}>
                    <Typography variant="body2" color="text.gray">
                      รายละเอียด
                    </Typography>
                    <Typography variant="body1b">
                      รบกวนพิจารณาด้วยครับ
                    </Typography>
                  </Box>

                  <Box flex={1}>
                    <Typography variant="body2" color="text.gray">
                      เอกสารแบบ
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                      <InsertPhotoIcon
                        sx={{ color: theme.palette.text.gray, fontSize: 32 }}
                      />
                      <Typography variant="body2">เอกสาร</Typography>
                    </Stack>
                  </Box>
                </AccordionDetails>

                <Divider
                  sx={{
                    marginX: theme.spacing(2),
                  }}
                />

                <AccordionActions sx={{ padding: theme.spacing(2) }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={onOpenDialog}
                    name="considerationCDO"
                  >
                    ผลพิจารณา
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={onOpenDialog}
                    name="confirmResultCDO"
                  >
                    ยึนยันผล
                  </Button>
                </AccordionActions>
              </AccordionStyle>
            </Stack>

            <Stack direction="row" justifyContent="flex-end" mt={3}>
              <Button
                variant="contained"
                onClick={closeModal}
                sx={{ width: 100 }}
              >
                ปิด
              </Button>
            </Stack>
          </>
        ) : (
          <>
            {dialogName.startsWith('confirm') ? ( // * RENDER CONFIRM RESULT DIALOG
              <>
                <Box width={1} pb={2}>
                  <Typography variant="h6">ยืนยันผล</Typography>
                </Box>
                <Box width={1} pb={2}>
                  <Typography variant="body1">
                    คุณต้องการยืนยันผลพิจารณาเพิ่มเติมใช่หรือไม่
                  </Typography>
                </Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap={1.5}
                  paddingTop={1}
                >
                  <Button variant="outlined" onClick={closeModal}>
                    ยกเลิก
                  </Button>
                  <LoadingButton
                    variant="contained"
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<></>}
                  >
                    ยืินยัน
                  </LoadingButton>
                </Stack>
              </>
            ) : (
              // * RENDER CONSIDERATION DIALOG
              <>
                <Box width={1} paddingBottom={2}>
                  <Typography variant="h6">ผลพิจารณา</Typography>
                </Box>
                <Stack gap={2} pb={theme.spacing(2)}>
                  <RadioGroupWrapper>
                    <CustomRadioGroup
                      formLabelProps={{ required: true }}
                      label="ผลการพิจารณาเพิ่มเติมจาก RAM"
                      name={dialogName}
                      options={considerationOptions}
                    />
                  </RadioGroupWrapper>

                  <Box width={1}>
                    <Typography variant="body2">
                      แนบเอกสารผลการตรวจสอบจาก CCM *
                    </Typography>
                    <UploadSingleFile
                      imgPath="/images/illustrate_upload-dc-admin.svg"
                      description={
                        <BasicUpload
                          handleUploadFile={(file) =>
                            handleUploadFile(
                              file,
                              ['jpg', 'jpeg', 'png', 'pdf'],
                              getUploadFile
                            )
                          }
                          required
                          sx={{
                            flexWrap: { xs: 'wrap', md: 'nowrap' },
                            gap: { xs: 2, md: 6 },
                            py: { xs: 4, md: 0 },
                            cursor: 'pointer',
                          }}
                          acceptFiles="image/jpeg, image/png, application/pdf'"
                          description={
                            <>
                              <Stack
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Typography
                                  variant="body1"
                                  color={theme.palette.primary.main}
                                  fontWeight={700}
                                >
                                  วางไฟล์ที่นี่
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color={theme.palette.grey[700]}
                                >
                                  JPG, JPEG, PNG, PDF
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color={theme.palette.grey[700]}
                                >
                                  ที่มีขนาดไฟล์ไม่เกิน 5 MB
                                </Typography>
                              </Stack>

                              <Typography variant="body1b">หรือ</Typography>
                            </>
                          }
                        />
                      }
                      accept={{
                        'image/jpeg': [],
                        'image/png': [],
                        'application/pdf': [],
                      }}
                      onDrop={(file) =>
                        handleUploadFile(
                          file,
                          ['png', 'jpeg', 'jpg', 'pdf'],
                          getUploadFile
                        )
                      }
                      sx={{
                        mb: { xs: 2, md: 0 },
                        width: 1,
                        cursor: 'pointer',
                        borderRadius: '8px',
                        border: `2px dashed ${theme.palette.common.black}1F`,
                        padding: theme.spacing(0, 4),

                        ...(!isEmpty(error) && {
                          backgroundColor: `${theme.palette.text.error}14 !important`,
                          borderColor: theme.palette.error.main,
                        }),
                      }}
                      textError={
                        typeof error === 'string' ? error : error?.message
                      }
                    />
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1.5}
                      mt={1}
                    >
                      <ErrorOutlineOutlinedIcon
                        color="primary"
                        fontSize="small"
                      />
                      <Typography variant="body2" color="primary.main">
                        สูงสุดไม่เกิน 3 รายการ
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap={1.5}
                  paddingY={theme.spacing(1)}
                >
                  <Button variant="outlined" onClick={closeModal}>
                    <Typography variant="buttonm">ยกเลิก</Typography>
                  </Button>
                  <LoadingButton
                    variant="contained"
                    onClick={onUpdateDecision}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<></>}
                    disabled={!formData?.[dialogName] || !data?.uploadData}
                  >
                    <Typography variant="buttonm">ยืนยัน</Typography>
                  </LoadingButton>
                </Stack>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  )
}
