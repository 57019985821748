import { useMemo } from 'react'
import { useAuth } from 'react-oidc-context'
// redux
import { useSelector } from '../redux/store'
import { selectInitialProfile } from '../redux/selector'
// constants
import { NOT_OWNER_WORK } from '../constants/dc-admin'
// utils
import _ from 'lodash'

export default function useGetMessageOwnerDialog(
  title: string,
  message: string
): {
  title: string
  message: string
} {
  const initialProfile = useSelector(selectInitialProfile)

  const { user } = useAuth()

  const getTrim = (text: string | undefined) => {
    if (_.isUndefined(text)) return text

    return text.replace(/ /g, '')
  }

  return useMemo(() => {
    if (
      !_.isEmpty(initialProfile?.contractor) &&
      getTrim(initialProfile?.contractor) ===
        getTrim(user?.profile?.preferred_username)
    ) {
      return {
        title,
        message,
      }
    }

    return {
      title: NOT_OWNER_WORK,
      message: 'คุณต้องการส่ง ขอแก้ไขเอกสารใช่หรือไม่',
    }
  }, [message, initialProfile, title, user?.profile.preferred_username])
}
