import _ from 'lodash'
import { store } from '../../../../../../redux/store'
import dayjs from 'dayjs'

export const mutateFilterSateToProp = () => {
  const { filterState }: any = store.getState().table

  if (!filterState.dateChecked) return {}

  const startDate = formatDate(_.get(filterState, 'startDate', null))
  const endDate = formatDate(_.get(filterState, 'endDate', null))
  return { startDate, endDate, }
}

const formatDate = (value: any) => {
  const format = 'MM/DD/YYYY'
  let dateValue = value
  if (dateValue && dateValue !== '')
    dateValue = dayjs(new Date(dateValue)).format(format)
  else dateValue = null
  return dateValue
}
