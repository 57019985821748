import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  notificationFilter,
  notificationClearCount,
  notificationIsRead,
} from '../../utils/apiPath'
import { callAPI } from '../../utils/callAPI'

interface notificationPayload {
  limit?: any
  page?: any
  user?: any
  uuid?: any
  isRead?: boolean
}

interface RejectWithValue {
  rejectWithValue: (value: any, error?: any) => any
}

export const getNotificationFilter = createAsyncThunk(
  'notification/getNotificationFilter',
  async (
    payload: notificationPayload,
    { rejectWithValue }: RejectWithValue
  ) => {
    try {
      const data = await callAPI({
        method: 'post',
        url: `${notificationFilter}`,
        body: payload,
      })
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const clearNotification = createAsyncThunk(
  'notification/clearNotification',
  async (
    payload: notificationPayload,
    { rejectWithValue }: RejectWithValue
  ) => {
    try {
      const data = await callAPI({
        method: 'post',
        url: `${notificationClearCount}`,
        body: payload,
      })
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const isReadNotification = createAsyncThunk(
  'notification/isReadNotification',
  async (
    payload: notificationPayload,
    { rejectWithValue }: RejectWithValue
  ) => {
    try {
      const data = await callAPI({
        method: 'put',
        url: `${notificationIsRead}`,
        body: payload,
      })
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
