export const filterItems = [
  {
    label: 'ช่วงเวลา',
    placeholder: 'เลือกช่วงเวลา',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    dateStateStart: 'startDate',
    dateStateFinish: 'endDate',
    dateStateDisplay: 'dateDisplayDate',
    type: 'daterange',
  },
]
