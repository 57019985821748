import { Container, Box } from '@mui/material'
import Paper from '@mui/material/Paper'
import HeaderTab from './components/HeaderTab'
import { useEffect } from 'react'
import { useDispatch } from '../../../redux/store'
import { setInitialListPage } from '../slices'
import ListContent from './components/ListContent'
import { fetchData } from './handler/fetchData'

const NotificationList = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setInitialListPage())
    fetchData(true)
  }, [])
  return (
    <Container maxWidth="md">
      <Box>
        <Paper sx={{ padding: 2, my: 6 }}>
          <HeaderTab />

          <ListContent />
        </Paper>
      </Box>
    </Container>
  )
}

export default NotificationList
