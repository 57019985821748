import { Grid } from '@mui/material'

import { shallowEqual, useSelector } from 'react-redux'
import { SliceModel } from '../../../../../redux/models'
import { ProcessResultApprovalStatus } from '../../../../../constants/status'
import { TimerAppointment } from './CardTimer/TimerAppointment'
import { TimerInterview } from './CardTimer/TimerInterview'
import { TimerReject } from './CardTimer/TimerReject'
import AssignInfo from './CardAssign/AssignInfo'
import { getValue } from '../../../../../utils/getValue'
import ProfileInfo from './CardInfo/ProfileInfo'
import { FullFormStat } from './CardFullForm/FullFormStat'
import { AssignProcess } from './CardAssign/AssignProcess'
import { AssignReject } from './CardAssign/AssignReject'
import { CardStepMobile } from '../Step'
import { FullForm } from './CardFullForm/FullForm'
import { TimerBlank } from './CardTimer/TimerBlank'
import * as _ from 'lodash'
import { reAssignAccessStatus } from '../../handler/validationStatus'

export default function CardProfile() {
  const { data } = useSelector(
    (state: SliceModel) => ({
      data: state.prospect.detail.data,
    }),
    shallowEqual
  )

  const canReAssignManager = _.includes(reAssignAccessStatus, data?.status)

  const getRanderCenterBox = () => {
    const status = getValue(data, 'status')

    switch (status) {
      case ProcessResultApprovalStatus.DL_ASSIGNED_MA:
        return <TimerAppointment />
      case ProcessResultApprovalStatus.I_ACCEPTED_INTERVIEW:
        return <TimerInterview />
      case ProcessResultApprovalStatus.DL_CHANGED:
      case ProcessResultApprovalStatus.I_PASSED_INTERVIEW:
      case ProcessResultApprovalStatus.DS_SAVED_FULL_FORM:
      case ProcessResultApprovalStatus.DS_SUBMITTED_FULL_FORM:
      case ProcessResultApprovalStatus.DS_REVIEWED:
      case ProcessResultApprovalStatus.DCV_REQUESTED_MEMO:
      case ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION:
      case ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION_CCM:
      case ProcessResultApprovalStatus.DCV_SENT_MAIL_DESCISION_CCM:
      case ProcessResultApprovalStatus.DCV_UPDATED_DESCISION:
      case ProcessResultApprovalStatus.DCV_PASSED_PROSPECT_HISTORY:
      case ProcessResultApprovalStatus.DCV_UPDATED_AGENT_CODE:
      case ProcessResultApprovalStatus.DCV_DROPPED:
      case ProcessResultApprovalStatus.DS_SAVED_FULL_FORM_MEMO:
      case ProcessResultApprovalStatus.DS_SUBMITTED_FULL_FORM_MEMO:
      case ProcessResultApprovalStatus.DS_REVIEWED_MEMO:
      case ProcessResultApprovalStatus.DCV_ACCEPTED_REVIEW:
      case ProcessResultApprovalStatus.DCV_REQUESTED_RCMS:
      case ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_PASSED:
      case ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_FAILED:
      case ProcessResultApprovalStatus.DCV_REQUESTED_E_LICENCING:
      case ProcessResultApprovalStatus.DCV_APPROVED_TO_BE_AGENT:
      case ProcessResultApprovalStatus.DCV_UPDATE_DESCISION_COMPLETED:
        return <FullFormStat />
      case ProcessResultApprovalStatus.I_DECLINED_INTERVIEW:
      case ProcessResultApprovalStatus.I_FAILED_INTERVIEW:
      case ProcessResultApprovalStatus.I_FAILED_OUT_OF_LIMIT:
      case ProcessResultApprovalStatus.I_TIME_OUT_OF_LIMIT:
        return <TimerReject />
      default:
        return <TimerBlank />
    }
  }
  const getRanderEndBox = () => {
    const status = getValue(data, 'status')
    if (status) {
      return <AssignInfo />
    } else {
      return <FullForm />
    }
  }

  const rederGrid = () => {
    const status = getValue(data, 'status')
    if (
      status === ProcessResultApprovalStatus.I_DECLINED_INTERVIEW ||
      status === ProcessResultApprovalStatus.I_FAILED_INTERVIEW ||
      status === ProcessResultApprovalStatus.DL_SUBMITTED_SHORT_FORM ||
      status === ProcessResultApprovalStatus.DL_SUCCESSFULLY_SENT_ASSIGN_RULE
    ) {
      return (
        <>
          <Grid item xs={12} md={6}>
            <ProfileInfo />
          </Grid>
          <Grid item xs={12} md={6}>
          {canReAssignManager && (
            <AssignProcess />
          )}
          </Grid>
        </>
      )
    } else if (
      status === ProcessResultApprovalStatus.DL_DROPPED ||
      status === ProcessResultApprovalStatus.DCV_DROPPED
    ) {
      return (
        <>
          <Grid item xs={12} md={6}>
            <ProfileInfo />
          </Grid>
          <Grid item xs={12} md={6}>
            <AssignReject />
          </Grid>
        </>
      )
    } else {
      return (
        <>
          <Grid item xs={12} md={4}>
            <ProfileInfo />
            <CardStepMobile />
          </Grid>
          <Grid item xs={12} md={4}>
            {getRanderCenterBox()}
          </Grid>
          <Grid item xs={12} md={4}>
            {getRanderEndBox()}
          </Grid>
        </>
      )
    }
  }
  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {rederGrid()}
      </Grid>
    </>
  )
}
