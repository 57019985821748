export interface DrawerState {
  isOpen?: boolean
  content: React.ReactNode
  isLoading?: boolean
}

export const initialState: DrawerState = {
  isOpen: false,
  content: null,
  isLoading: false,
}
