import _ from 'lodash'
import { useDispatch } from '../../../redux/store'
import DateRangeInput from '../../../components/DateRangeInput'
import { setHandleChange } from '../../../components/Table/redux'
import dayjs from 'dayjs'

export const CustomDatePicker: React.FC<{ filterState: any; item: any }> = ({
  filterState,
  item,
}) => {
  const dispatch = useDispatch()
  const getDateState = (item: any) => {
    return {
      startDate: filterState[item.dateStateStart]
        ? dayjs(filterState[item.dateStateStart]).toDate()
        : dayjs().toDate(),
      endDate: filterState[item.dateStateFinish]
        ? dayjs(filterState[item.dateStateFinish]).toDate()
        : dayjs().toDate(),
      display: _.get(filterState, item.dateStateDisplay, false),
      key: 'selection',
    }
  }

  const handleSelectDate = (selectedDates: any, filter: any, item: any) => {
    const objState = {
      ...filter,
      [item.dateStateStart]: selectedDates.startDate,
      [item.dateStateFinish]: selectedDates.endDate,
      [item.dateStateDisplay]: selectedDates.display,
    }
    dispatch(setHandleChange({ key: 'filterState', value: objState }))
  }

  return (
    <DateRangeInput
      dateState={getDateState(item)}
      onChange={(selectedDates) => {
        handleSelectDate(selectedDates, filterState, item)
      }}
    />
  )
}
