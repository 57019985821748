//CRUD
export const CRUDListFilterPath = '/crud/filter'
export const CRUDListDownloadPath = '/crud/download'

// assign-rule
export const ManagerListFilterPath = '/simple-rule/filter/manager'
export const ManagerDownloadAllPath = '/simple-rule/download/all/manager'
export const ManagerListDownloadPath = '/simple-rule/download/manager'
export const managerStatusPath = '/simple-rule/status/manager'
export const validateManager = '/simple-rule/validate/manager'
export const importManager = '/simple-rule/import/manager'
export const simpleRuleProvince = '/simple-rule/provinces'
export const simpleRuleWorkingArea = '/simple-rule/filter/wokingarea'
export const simpleRuleNearbyArea = '/simple-rule/nearby-area'

// manage
export const manageFile = '/file/manage'

//prospect
export const prospectList = '/appointment/list'
export const prospectListDownload = '/appointment/download'
export const prospectUpdateStatus = 'appointment/update/status'
export const validateProspect = '/candidate/validate/prospect'
export const candidateFilterList = '/candidate/filter'
export const importProspect = '/candidate/import/prospect'
export const prospectDeail = '/appointment/detail'
export const CandidateListDownloadPath = '/candidate/download'
export const CandidateDownloadAllPath = '/candidate/download/all'

//permission
export const permission = '/permission'
export const permissionGroup = '/permissionGroup'
export const filterStaffProfile = '/staff-profile/filter'
export const downloadPermissionGroup = '/permissionGroup/download'
export const filterPermissionGroup = '/permissionGroup/filter'
export const historyPermissionGroup = '/permissionGroup/history'

// dc-admin
export const dcAdminDashboard = '/dc-admin/dashboard'
export const dcAdminExport = '/dc-admin/export'
export const dcAdminListDownload = '/dc-admin/listing/download'

export const dcAdminListOicDownload = '/report/download/oic'
export const dcAdminListFullFormReceiptDownload = '/report/download/receipt'
export const dcAdminListFullFormWithdrawalDownload =
  '/report/download/withdrawal'
export const dcAdminListRcmsDownload = '/report/download/rcms'

// notification
export const notificationFilter = '/notification/filter'
export const notificationClearCount = '/notification/clear-count'
export const notificationIsRead = '/notification/is-read'

// dc-admin -> full-form
export const getFullFormData = (prospectId: string) =>
  `/fullform/dc/review-fullform/${prospectId}`
export const sendToOIC = '/fullform/submit/send-to-oic'

export const sendToRCMS = '/fullform/submit/send-to-rcms'
export const requestMemo = '/fullform/request-memo'
export const createNote = '/fullform/created/noted'
export const deleteNote = '/fullform/deleted/noted'
export const notes = (fullFormUuid: string) =>
  `/fullform/dc/noted-list/${fullFormUuid}`
export const updateAudit = 'fullform/update/audit-info'
export const initProfile = (prospectUuid: string) =>
  `/fullform/dc/init/${prospectUuid}`
export const updateProcessStatus = '/fullform/update/process-status'
export const updateAddContracter = '/fullform/add-contractor'
export const updatePayment = '/fullform/update/payment-status'
export const updateConsideration = '/fullform/update/consideration'
export const checkHistory = (fullformUuid: string) =>
  `fullform/dc/check-history/${fullformUuid}`
export const variousHistoryStatus = '/fullform/status/various'
export const verifyBackground = '/fullform/update/background-status'
export const rejectProspect = '/fullform/rejected'
export const updateLicenseAndAgentCode = '/fullform/submit/data-license-agent'
export const sendToCCM = '/fullform/submit/send-to-ccm'
export const uploadResult = '/fullform/submit-result'
export const rejectByOIC = '/fullform/update/reject-by-oic'
export const dataLicense = (fullFormUuid: string) =>
  `fullform/dc/get-data-license/${fullFormUuid}`
export const updateRequestConsideration =
  '/fullform/update/request-consideration'
export const createRequestConsideration =
  '/fullform/created/request-consideration'

// file storage
export const fileStorage = '/file-storage/get'
export const uploadAttachment = '/file-storage/upload/attachments'
