import { type Dispatch, type SetStateAction } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useSelector } from '../../../../redux/store'
import { selectProfileDetails } from '../../../../redux/selector'
import ListItem from './ListItem'
import HeaderActions from './HeaderActions'
import {
  EVER_NEVER_OPTIONS,
  HAVE_DONT_OPTIONS,
} from '../../../../constants/status'
import {
  getLabelFullFormArrText,
  FULLFORM,
} from '../../../../constants/dc-admin'
import { displayDateTime } from '../../../../utils/convertFormatDateTime'
import { DateFormat } from '../../../../constants/dateFormat'

type Props = {
  setTab: Dispatch<SetStateAction<number>>
}

export default function ProfileDetail({ setTab }: Readonly<Props>) {
  const profileDetails = useSelector(selectProfileDetails)

  if (!profileDetails) {
    return null
  }

  return (
    <Stack spacing={3}>
      {/* HEADER ACTION SECTION */}
      <HeaderActions titleStep="Education-Work" setTab={setTab} />

      {/* LIST ITEM SECTION */}
      <Box>
        {/* LIST ITEM HEADER */}
        <Typography variant="body1b" color="text.primary">
          ประวัติการศึกษา
        </Typography>
        <Stack
          minHeight={56}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="tableHeader" flex={1} pl={2}>
            หัวข้อ
          </Typography>
          <Typography variant="tableHeader" flex={1}>
            ข้อมูลที่กรอก
          </Typography>
        </Stack>

        {/* LIST ITEM CONTENT */}
        <ListItem
          listData={[
            {
              label: 'การศึกษาสูงสุด',
              value:
                getLabelFullFormArrText(
                  FULLFORM.education,
                  profileDetails?.personal?.highestEducation
                ) ?? '-',
            },
            {
              label: 'ประวัติการศึกษา ',
              value: profileDetails.educations?.[0]
                ? profileDetails.educations?.[0]?.educationHistory
                : '-',
            },
            {
              label: 'จากปี',
              value: profileDetails.educations?.[0]
                ? displayDateTime(
                    profileDetails.educations?.[0]?.startYear,
                    DateFormat.YYYY
                  )
                : '-',
            },
            {
              label: 'ถึงปี',
              value: profileDetails.educations?.[0]
                ? displayDateTime(
                    profileDetails.educations?.[0]?.endYear,
                    DateFormat.YYYY
                  )
                : '-',
            },
            {
              label: 'สำเร็จขั้นสูงสุด ',
              value: profileDetails.educations?.[0]
                ? profileDetails.educations?.[0]?.graduation
                : '-',
            },
            {
              label: 'ประกาศนียบัตร ',
              value: profileDetails.educations?.[0]
                ? profileDetails.educations?.[0]?.diploma
                : '-',
            },
          ]}
        />

        <Box minHeight={56}></Box>

        <ListItem
          listData={[
            {
              label: 'ประวัติการศึกษา',
              value: profileDetails.educations?.[1]
                ? profileDetails.educations?.[1]?.educationHistory
                : '-',
            },
            {
              label: 'จากปี',
              value: profileDetails.educations?.[1]
                ? displayDateTime(
                    profileDetails.educations?.[1]?.startYear,
                    DateFormat.YYYY
                  )
                : '-',
            },
            {
              label: 'ถึงปี',
              value: profileDetails.educations?.[1]
                ? displayDateTime(
                    profileDetails.educations?.[1]?.endYear,
                    DateFormat.YYYY
                  )
                : '-',
            },
            {
              label: 'สำเร็จขั้นสูงสุด ',
              value: profileDetails.educations?.[1]
                ? profileDetails.educations?.[1]?.graduation
                : '-',
            },
            {
              label: 'ประกาศนียบัตร ',
              value: profileDetails.educations?.[1]
                ? profileDetails.educations?.[1]?.diploma
                : '-',
            },
            {
              label: 'ท่านพูดภาษาใดได้บ้าง',
              value: profileDetails.personal.language1 ?? '-',
            },
            {
              label: '',
              value: profileDetails.personal.language2 ?? '-',
            },
            {
              label: '',
              value: profileDetails.personal.language3 ?? '-',
            },
          ]}
        />

        <ListItem
          subheader={
            <Typography variant="body1b" color="text.primary" my={2}>
              ประสบการณ์การทำงาน
            </Typography>
          }
          listData={[
            {
              label: 'ประวัติการทำงานปัจจุบัน ชื่อนายจ้าง',
              value: profileDetails.work?.companyName ?? '-',
            },
            {
              label: 'จากปี',
              value: displayDateTime(
                profileDetails.work?.startYear,
                DateFormat.YYYY
              ),
            },
            {
              label: 'ถึงปี',
              value: displayDateTime(
                profileDetails.work?.endYear,
                DateFormat.YYYY
              ),
            },
            {
              label: 'อื่น ๆ',
              value: profileDetails.work?.occupationOther ?? '-',
            },
            {
              label: 'รายได้เฉลี่ย',
              value:
                getLabelFullFormArrText(
                  FULLFORM.salary,
                  profileDetails.work.salary
                ) ?? '-',
            },
            {
              label: 'ช่องทางการสมัครเข้ามาเป็นตัวแทน',
              value: '-',
            },
            {
              label: 'สมัครเข้ามาเป็นตัวแทนประกันชีวิตโดย',
              value:
                getLabelFullFormArrText(
                  FULLFORM.channel,
                  profileDetails?.personal?.channel
                ) ?? '-',
            },
          ]}
        />

        <ListItem
          subheader={
            <Typography variant="body1b" color="text.primary" my={2}>
              รายละเอียดอื่น ๆ
            </Typography>
          }
          listData={[
            {
              label: 'บุคคลที่จะติดต่อด้วยกรณีจำเป็น',
              value: profileDetails.personal.contactPersonName ?? '-',
            },
            {
              label: 'เบอร์โทรศัพท์',
              value: profileDetails.personal.contactPersonMobile ?? '-',
            },

            {
              label: 'ที่อยู่ ',
              value: profileDetails.personContact.address ?? '-',
            },
            {
              label: 'ประกันชีวิต',
              value: '',
            },
            {
              label:
                'ท่านมีประกันชีวิตกับบริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัดมหาชน (มีหรือไม่)',
              value:
                getLabelFullFormArrText(
                  FULLFORM.YesNo,
                  profileDetails?.personalAssets?.insuranceKTAXA
                ) ?? '-',
            },
            {
              label: 'เบื้ยประกัน',
              value: profileDetails.personalAssets.insurancePremium ?? '- ',
            },
            {
              label: 'ท่านมีประกันชีวิตกับบริษัทประกันชีวิตอื่น ๆ หรือไม่',
              value: profileDetails.personalAssets.insuranceOtherName ?? '-',
            },
            {
              label: 'เบื้ยประกัน',
              value: profileDetails.personalAssets.insuranceOtherPremium ?? '-',
            },
            {
              label: 'การเป็นตัวแทนประกัน',
              value: '',
            },
            {
              label:
                'ท่านมีบุคคลในครอบครัวและ/หรือญาติเป็นตัวแทนประกันชีวิตหรือพนักงานบริษัท กรุงไทย-แอกซ่า ประกันชีวิต จํากัด (มหาชน) หรือบริษัทประกันชีวิตอื่นหรือไม่ถ้ามีโปรดให้รายละเอียด',
              value:
                getLabelFullFormArrText(
                  FULLFORM.YesNo,
                  profileDetails?.personalAssets?.agentRelative
                ) ?? '-',
            },
            {
              label: 'ชื่อ นามสกุล',
              value: profileDetails.agentRelatives?.[0]?.fullName ?? '-',
            },
            {
              label: 'ความสัมพันธ์',
              value: profileDetails.agentRelatives?.[0]?.relation ?? '-',
            },
            {
              label: 'บริษัท',
              value: profileDetails.agentRelatives?.[0]?.company ?? '-',
            },
            {
              label: 'ตำแหน่งหน้าที่',
              value: profileDetails.agentRelatives?.[0]?.position ?? '-',
            },
            {
              label: 'ชื่อหน่วย',
              value: profileDetails.agentRelatives?.[0]?.department ?? '- ',
            },
            {
              label: 'รหัสตัวแทน',
              value: profileDetails.agentRelatives?.[0]?.agentCode ?? '-',
            },
          ]}
        />

        <Box minHeight={56}></Box>
        <ListItem
          listData={[
            {
              label: 'ชื่อ นามสกุล',
              value: profileDetails.agentRelatives?.[1]?.fullName ?? '-',
            },
            {
              label: 'ความสัมพันธ์',
              value: profileDetails.agentRelatives?.[1]?.relation ?? '-',
            },
            {
              label: 'บริษัท',
              value: profileDetails.agentRelatives?.[1]?.company ?? '-',
            },
            {
              label: 'ตำแหน่งหน้าที่ ',
              value: profileDetails.agentRelatives?.[1]?.position ?? '-',
            },
            {
              label: 'ชื่อหน่วย',
              value: profileDetails.agentRelatives?.[1]?.department ?? '-',
            },
            {
              label: 'รหัสตัวแทน',
              value: profileDetails.agentRelatives?.[1]?.agentCode ?? '-',
            },
            {
              label:
                'ท่านเคยเป็นตัวแทนกับบริษัท กรุงไทย-แอกซ่า ประกันชีวิต จํากัด (มหาชน) หรือไม่',
              value:
                EVER_NEVER_OPTIONS[
                  profileDetails.personalAssets.previousAgentStatus
                ] ?? '-',
            },
            {
              label: 'ชื่อหน่วย',
              value: profileDetails.personalAssets.previousAgentCompany ?? '-',
            },
            {
              label: 'รหัสตัวแทน',
              value: profileDetails.personalAssets.previousAgentCode ?? '-',
            },
            {
              label: 'ท่านเคยเป็นตัวแทนกับบริษัทประกันชีวิตอื่นๆ หรือไม่',
              value:
                EVER_NEVER_OPTIONS[
                  profileDetails.personalAssets.otherAgentStatus
                ] ?? '-',
            },
            {
              label: 'ชื่อหน่วย',
              value:
                EVER_NEVER_OPTIONS[
                  profileDetails.personalAssets.otherAgentCompany
                ] ?? '-',
            },
            {
              label: 'รหัสตัวแทน',
              value:
                EVER_NEVER_OPTIONS[
                  profileDetails.personalAssets.otherAgentCode
                ] ?? '-',
            },
            {
              label: 'บุคคลอ้างอิง',
              value: '',
            },
            {
              label: 'ชื่อ นามสกุล',
              value: profileDetails.personContact?.fullName ?? '-',
            },
            {
              label: 'โทรศัพท์',
              value: profileDetails.personContact?.mobileNo ?? '-',
            },
            {
              label: 'ที่อยู่',
              value: profileDetails.personContact?.address ?? '-',
            },
            {
              label: 'อาชีพ',
              value: profileDetails.personContact?.occupation ?? '-',
            },
            {
              label: 'รู้จักมากี่ปี',
              value: profileDetails.personContact?.knownForYear ?? '-',
            },
            {
              label: 'อื่น ๆ',
              value: '',
            },
            {
              label: 'ท่านมีทรัพย์สินอื่นหรือไม่?',
              value:
                HAVE_DONT_OPTIONS[profileDetails.personalAssets.assetEtc] ??
                '-',
            },
            {
              label: 'ท่านมียานพาหนะหรือไม่',
              value:
                HAVE_DONT_OPTIONS[profileDetails.personalAssets.vehicle] ?? '-',
            },
            {
              label: 'ท่านมีประกันยานพาหนะหรือไม่',
              value:
                HAVE_DONT_OPTIONS[
                  profileDetails.personalAssets.insuranceVehicle
                ] ?? '-',
            },
          ]}
        />
      </Box>
    </Stack>
  )
}
