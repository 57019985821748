import Radio from '@mui/material/Radio'
import RadioGroup, { type RadioGroupProps } from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel, { type FormLabelProps } from '@mui/material/FormLabel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
// redux
import { useDispatch, useSelector } from '../../../redux/store'
import { setFieldValue } from '../../CRUD/slices/'
import {
  selectCrudFormData,
  selectCrudFormError,
} from '../../../redux/selector'
import { FormHelperText } from '@mui/material'

interface Props extends RadioGroupProps {
  label: React.ReactNode
  name: string
  options: {
    label: string
    value: string | boolean | number
  }[]
  formLabelProps?: FormLabelProps
  defaultValue?: unknown
  disabled?: boolean
}

export default function CustomRadioGroup({
  label,
  name,
  options,
  defaultValue,
  formLabelProps,
  disabled,
  ...other
}: Readonly<Props>) {
  const dispatch = useDispatch()

  const formData = useSelector(selectCrudFormData)
  const formError = useSelector(selectCrudFormError)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setFieldValue({
        key: `formData.${name}`,
        value: (event.target as HTMLInputElement).value,
      })
    )
  }

  return (
    <FormControl>
      <FormLabel id="controlled-radio-buttons-group" {...formLabelProps}>
        {label}
      </FormLabel>
      <RadioGroup
        aria-labelledby="controlled-radio-buttons-group"
        name={name}
        value={defaultValue ?? formData?.name}
        onChange={handleChange}
        row
        {...other}
      >
        {options.map((option) => (
          <FormControlLabel
            sx={{ pr: 5 }}
            key={option.label}
            value={option.value}
            control={<Radio checkedIcon={<CheckCircleIcon />} />}
            disabled={disabled}
            label={option.label}
          />
        ))}
      </RadioGroup>

      {!!formError && (
        <FormHelperText error sx={{ px: 2 }}>
          {formError}
        </FormHelperText>
      )}
    </FormControl>
  )
}
