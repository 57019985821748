import styled from '@mui/material/styles/styled'
import { StyledContentProps } from '../../../components/Styled/types/styledContentProps'
import { Box, CircularProgress } from '@mui/material'

export const LoadingCircular = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})<StyledContentProps>(({ theme, isLoading }) => ({
  display: isLoading ? 'block' : 'none',
  color: theme.palette?.primary?.main,
}))

export const NotiBoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  padding: 5,
  '&:hover': {
    backgroundColor: '#F5F5F5',
  },
  margin: 'auto',
}))
