import _ from 'lodash'
import { ProcessResultApprovalStatus } from '../status'

export interface CRUDFormData {
  [key: string]: any
}

export const APPLICATION_STATUS = {
  PENDING: 'PENDING',
  PROCESS: 'PROCESS',
  MEMO: 'MEMO',
  REQUEST_DESCISION: 'REQUEST_DESCISION',
  OIC: 'OIC',
  RCMS: 'RCMS',
  SUCCESS: 'SUCCESS',
}

export const APPLICATION_STATUS_TH = {
  ALL: 'ทั้งหมด',
  PENDING: 'รอดำเนินการ',
  MEMO: 'ขอแก้ไขเอกสาร',
  PROCESS: 'กำลังตรวจสอบ',
  REQUEST_DESCISION: 'ส่งพิจารณาเพิ่มเติม',
  OIC: 'คปภ.',
  RCMS: 'RCMS',
  SUCCESS: 'สำเร็จ',
}

export const APPLICATION_STATUS_FIELD = {
  pending: 'PENDING',
  requestEdit: 'REQUEST_EDIT',
  inProgress: 'IN_PROGRESS',
  waitingForLicense: 'WAITING_FOR_LICENSE',
  waitingForApprove: 'WAITING_FOR_APPROVE',
  rcms: 'RCMS',
  success: 'SUCCESS',
}

export const EXPORT_TYPE = {
  APPLICANT: 'APPLICANT',
  RECEIPT: 'RECEIPT',
  WITHDRAWAL_SLIP: 'WITHDRAWAL_SLIP',
  REPORT: 'REPORT',
  VERIFY_FAILED: 'VERIFY_FAILED',
  RCMS: 'RCMS',
}

export const EXPORT_TYPE_TH = {
  APPLICANT: 'รายงานหนังสือแสดงความต้องการขอรับใหม่',
  RECEIPT: 'รายงานข้อมูลใบเสร็จรับเงิน',
  WITHDRAWAL_SLIP: 'รานงานข้อมูลใบเบิกเงิน',
  REPORT: 'รายงาน',
  VERIFY_FAILED: 'ข้อมูลผู้ที่ไม่ผ่านการตรวจสอบ',
  RCMS: 'รายงานข้อมูลส่งออก RCMS',
}
export interface DC_STATUS_SUB {
  label: string
  status: string[]
}
export interface DC_STATUS_DATA {
  name: string
  label: string
  status: string[]
  subStatus: DC_STATUS_SUB[]
}

export const DC_STATUS: DC_STATUS_DATA[] = [
  {
    name: 'PENDING',
    label: 'รอดำเนินการ',
    status: [ProcessResultApprovalStatus.DS_REVIEWED],
    subStatus: [],
  },
  {
    name: 'PROCESS',
    label: 'กำลังตรวจสอบ',
    status: [ProcessResultApprovalStatus.DCV_ACCEPTED_REVIEW],
    // subStatus: [
    //   {
    //     label: 'ประวัติส่วนตัว',
    //     status: ['PERSONAL'],
    //   },
    //   {
    //     label: 'ประวัติการศึกษา / ประสบการณ์การทำงาน / รายละเอียดอื่นๆ',
    //     status: ['EXPERIENCE'],
    //   },
    //   {
    //     label: 'รายการลดหย่อนเพื่อการคํานวณภาษีเงินได้บุคคลธรรมดา',
    //     status: ['TAX'],
    //   },
    //   {
    //     label: 'นโยบาย',
    //     status: ['POLICY'],
    //   },
    //   {
    //     label: 'แนบเอกสาร',
    //     status: ['ATTACHMENT'],
    //   },
    // ],
    subStatus: [],
  },
  {
    name: 'MEMO',
    label: 'ขอแก้ไขเอกสาร',
    status: [
      ProcessResultApprovalStatus.DCV_REQUESTED_MEMO,
      ProcessResultApprovalStatus.DS_SAVED_FULL_FORM_MEMO,
      ProcessResultApprovalStatus.DS_SUBMITTED_FULL_FORM_MEMO,
      ProcessResultApprovalStatus.DS_REVIEWED_MEMO,
    ],
    subStatus: [
      {
        label: 'รอการแก้ไข',
        status: [
          ProcessResultApprovalStatus.DCV_REQUESTED_MEMO,
          ProcessResultApprovalStatus.DS_SAVED_FULL_FORM_MEMO,
        ],
      },
      {
        label: 'รอผู้จัดการอนุมัติ',
        status: [ProcessResultApprovalStatus.DS_SUBMITTED_FULL_FORM_MEMO],
      },
      {
        label: 'ได้รับการแก้ไขแล้ว',
        status: [ProcessResultApprovalStatus.DS_REVIEWED_MEMO],
      },
    ],
  },
  {
    name: 'REQUEST_DESCISION',
    label: 'ส่งพิจารณาเพิ่มเติม',
    status: [
      ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION_CCM,
      ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION,
      ProcessResultApprovalStatus.DCV_SENT_MAIL_DESCISION_CCM,
      ProcessResultApprovalStatus.DCV_UPDATE_DESCISION_COMPLETED,
      ProcessResultApprovalStatus.DCV_UPDATED_DESCISION,
    ],
    subStatus: [
      {
        label: 'รอส่ง CCM',
        status: [ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION_CCM],
      },
      {
        label: 'รอ CCM ตรวจสอบ',
        status: [ProcessResultApprovalStatus.DCV_SENT_MAIL_DESCISION_CCM],
      },
      // {
      //   label: 'RAM/SRAM',
      //   status: [],
      // },
      // {
      //   label: 'CADTO',
      //   status: [],
      // },
      // {
      //   label: 'CDO',
      //   status: [],
      // },
      {
        label: 'รอพิจารณา',
        status: [ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION],
      },
      {
        label: 'ได้รับผลพิจารณา',
        status: [ProcessResultApprovalStatus.DCV_UPDATED_DESCISION],
      },
    ],
  },
  {
    name: 'OIC',
    label: 'คปภ.',
    status: [
      ProcessResultApprovalStatus.DCV_UPDATED_DESCISION,
      ProcessResultApprovalStatus.DCV_APPROVED_TO_BE_AGENT,
      ProcessResultApprovalStatus.DCV_REQUESTED_E_LICENCING,
      ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_PASSED,
      ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_FAILED,
    ],
    subStatus: [
      {
        label: 'รอส่ง คปภ.',
        status: [
          ProcessResultApprovalStatus.DCV_APPROVED_TO_BE_AGENT,
          ProcessResultApprovalStatus.DCV_UPDATED_DESCISION,
        ],
      },
      {
        label: 'รอเลขใบอนุญาต',
        status: [ProcessResultApprovalStatus.DCV_REQUESTED_E_LICENCING],
      },
      {
        label: 'ได้รับเลขใบอนุญาต',
        status: [ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_PASSED],
      },
      {
        label: 'ไม่ผ่านการออกใบอนุญาตกับ คปภ.',
        status: [ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_FAILED],
      },
    ],
  },
  {
    name: 'RCMS',
    label: 'RCMS',
    status: [
      ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_PASSED,
      ProcessResultApprovalStatus.DCV_REQUESTED_RCMS,
      ProcessResultApprovalStatus.DCV_UPDATED_AGENT_CODE,
    ],
    subStatus: [
      {
        label: 'รอออกรหัสตัวแทนกับ RCMS',
        status: [ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_PASSED],
      },
      {
        label: 'รอรหัสตัวแทน',
        status: [ProcessResultApprovalStatus.DCV_REQUESTED_RCMS],
      },
      {
        label: 'ได้รับรหัสตัวแทนแล้ว',
        status: [ProcessResultApprovalStatus.DCV_UPDATED_AGENT_CODE],
      },
    ],
  },
  {
    name: 'SUCCESS',
    label: 'สำเร็จ',
    status: [
      ProcessResultApprovalStatus.DCV_DROPPED,
      ProcessResultApprovalStatus.DCV_UPDATED_AGENT_CODE,
    ],
    subStatus: [
      {
        label: 'ผ่าน',
        status: [ProcessResultApprovalStatus.DCV_UPDATED_AGENT_CODE],
      },
      {
        label: 'ถูกปฎิเสธ',
        status: [ProcessResultApprovalStatus.DCV_DROPPED],
      },
    ],
  },
]

export const workerUrl =
  'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'

export enum TITLE_OPTIONS {
  miss = 'นางสาว',
  mrs = 'นาง',
  mr = 'นาย',
  'buddhist monk' = 'พระสงฆ์',
  rev = 'บาทหลวง',
  'mom luang' = 'หม่อมหลวง',
  'mom rajawong' = 'หม่อมราชวงศ์',
  'mom chao' = 'หม่อมเจ้า',
  'emeritus professor' = 'ศาสตราจารย์เกียรติคุณ (กิตติคุณ)',
  professor = 'ศาสตราจารย์',
  'assistant professor' = 'ผู้ช่วยศาสตราจารย์',
  'associate professor' = 'รองศาสตราจารย์',
}

export const NOT_OWNER_WORK = 'คุณไม่ใช่เจ้าของงาน'

export const FULLFORM = {
  gender: [
    {
      label: 'ชาย',
      value: 'M',
    },
    {
      label: 'หญิง',
      value: 'F',
    },
  ],
  title: [
    {
      label: 'นาย',
      value: '1',
    },
    {
      label: 'นาง',
      value: '2',
    },
    {
      label: 'นางสาว',
      value: '3',
    },
    {
      label: 'ว่าที่ร้อยตรีหญิง',
      value: '4',
    },
    {
      label: 'ว่าที่ร้อยตรี',
      value: '5',
    },
    {
      label: 'สิบเอก',
      value: '6',
    },
    {
      label: 'ดาบตำรวจ',
      value: '7',
    },
    {
      label: 'จ่าสิบเอก',
      value: '8',
    },
    {
      label: 'สิบเอกหญิง',
      value: '9',
    },
    {
      label: 'ว่าที่ร้อยโท',
      value: '10',
    },
    {
      label: 'จ่าสิบตำรวจ',
      value: '11',
    },
    {
      label: 'สิบตรี',
      value: '12',
    },
    {
      label: 'สิบตำรวจเอก',
      value: '13',
    },
    {
      label: 'ร้อยตำรวจตรี',
      value: '14',
    },
    {
      label: 'พันตำรวจโท',
      value: '15',
    },
    {
      label: 'จ่าสิบตรี',
      value: '16',
    },
    {
      label: 'ร้อยตรี',
      value: '17',
    },
    {
      label: 'พันจ่าอากาศเอก',
      value: '18',
    },
    {
      label: 'จ่าสิบเอกหญิง',
      value: '19',
    },
    {
      label: 'ร้อยตำรวจเอก',
      value: '20',
    },
    {
      label: 'พันตรี',
      value: '21',
    },
    {
      label: 'ดาบตำรวจหญิง',
      value: '22',
    },
    {
      label: 'สิบโทหญิง',
      value: '23',
    },
    {
      label: 'สิบโท',
      value: '24',
    },
    {
      label: 'สิบตำรวจตรี',
      value: '25',
    },
    {
      label: 'พันเอก',
      value: '26',
    },
    {
      label: 'พันตำรวจโทหญิง',
      value: '27',
    },
    {
      label: 'จ่าอากาศตรี',
      value: '28',
    },
    {
      label: 'สิบตำรวจโท',
      value: '29',
    },
    {
      label: 'เรือเอกหญิง',
      value: '30',
    },
    {
      label: 'ร้อยเอก',
      value: '31',
    },
    {
      label: 'ร้อยโท',
      value: '32',
    },
    {
      label: 'พันตำรวจเอก',
      value: '33',
    },
    {
      label: 'พันจ่าเอก',
      value: '34',
    },
    {
      label: 'จ่าเอก',
      value: '35',
    },
    {
      label: 'จ่าตรี',
      value: '36',
    },
    {
      label: 'จ่าสิบตรีหญิง',
      value: '37',
    },
    {
      label: 'สิบตำรวจตรีหญิง',
      value: '38',
    },
    {
      label: 'ว่าที่ร้อยเอก',
      value: '39',
    },
    {
      label: 'ว่าที่พันตรี',
      value: '40',
    },
    {
      label: 'เรืออากาศตรี',
      value: '41',
    },
    {
      label: 'พลทหาร',
      value: '42',
    },
    {
      label: 'นาวาตรี',
      value: '43',
    },
    {
      label: 'จ่าสิบตำรวจหญิง',
      value: '44',
    },
    {
      label: 'หม่อมหลวง',
      value: '45',
    },
    {
      label: 'ว่าที่เรือตรี',
      value: '46',
    },
    {
      label: 'ว่าที่ร้อยตำรวจตรี',
      value: '47',
    },
    {
      label: 'เรือเอก',
      value: '48',
    },
    {
      label: 'เรือโทหญิง',
      value: '49',
    },
    {
      label: 'ร้อยตำรวจโทหญิง',
      value: '50',
    },
    {
      label: 'ร้อยตำรวจโท',
      value: '51',
    },
    {
      label: 'ร้อยตำรวจเอกหญิง',
      value: '52',
    },
    {
      label: 'ร้อยตรีหญิง',
      value: '53',
    },
    {
      label: 'มหาดเล็ก',
      value: '54',
    },
    {
      label: 'มหาเสวก',
      value: '55',
    },
    {
      label: 'พันโท',
      value: '56',
    },
    {
      label: 'พันตำรวจตรีหญิง',
      value: '57',
    },
    {
      label: 'พันจ่าอากาศโท',
      value: '58',
    },
    {
      label: 'พลเอก',
      value: '59',
    },
    {
      label: 'พลตรี',
      value: '60',
    },
    {
      label: 'พันเอกอากาศ',
      value: '61',
    },
    {
      label: 'ฝ่ายการตลาด',
      value: '62',
    },
    {
      label: 'นาวาอากาศเอก',
      value: '63',
    },
    {
      label: 'นาวาอากาศโท',
      value: '64',
    },
    {
      label: 'นาวาอากาศตรี',
      value: '65',
    },
    {
      label: 'ด็อกเตอร์',
      value: '66',
    },
  ],
  maritalStatus: [
    {
      label: 'สมรสแล้ว',
      value: 'M',
    },
    {
      label: 'โสด',
      value: 'S',
    },
    {
      label: 'หม้าย',
      value: 'W',
    },
    {
      label: 'หย่า',
      value: 'D',
    },
  ],
  experienceSaleInsurance: [
    {
      label: 'ไม่มีประสบการณ์',
      value: '0',
    },
    {
      label: 'น้อยกว่า 1 ปี',
      value: '1',
    },
    {
      label: '1-2 ปี',
      value: '2',
    },
    {
      label: '3-5 ปี',
      value: '5',
    },
    {
      label: '5-8 ปี',
      value: '8',
    },
    {
      label: 'มากกว่า 8 ปี',
      value: '9',
    },
  ],
  workTypeInsurance: [
    {
      label: 'เต็มเวลา',
      value: 'FT',
    },
    {
      label: 'บางเวลา',
      value: 'PT',
    },
  ],
  taxMaritalStatus: [
    {
      label: 'โสด',
      value: 'M',
    },
    {
      label: 'หม้าย',
      value: 'S',
    },
    {
      label: 'คู่สมรสไม่มีเงินได้',
      value: 'MN',
    },
    {
      label: 'คู่สมรสมีเงินได้ แยกยื่นหรือรวมยื่น',
      value: 'MC',
    },
    {
      label: 'คู่สมรสมีเงินได้ จดทะเบียนระหว่างปี',
      value: 'MY',
    },
    {
      label: 'คู่สมรสมีเงินได้ หย่าหรือตายระหว่างปี',
      value: 'MD',
    },
  ],
  nationality: [
    {
      label: 'สัญชาติไทย',
      value: 'T',
    },
    {
      label: 'มีถิ่นที่อยู่ในไทย',
      value: 'N',
    },
  ],
  education: [
    {
      label: 'ประถมศึกษา',
      value: 'US',
    },
    {
      label: 'มัธยมศึกษา',
      value: 'PC',
    },
    {
      label: 'มัธยมศึกษาตอนปลาย',
      value: 'PS',
    },
    {
      label: 'อาชีวะ',
      value: 'DP',
    },
    {
      label: 'ปริญญาตรี/วิชาชีพเฉพาะหรือสูงกว่า',
      value: 'GR',
    },
  ],
  occupation: [
    {
      label: 'พนักงานขาย',
      value: 'S',
    },
    {
      label: 'วิชาชีพเฉพาะ',
      value: 'T',
    },
    {
      label: 'ครู',
      value: 'P',
    },
    {
      label: 'นักธุรกิจ',
      value: 'B',
    },
    {
      label: 'เสมียนพนักงาน',
      value: 'C',
    },
    {
      label: 'รับราชการ',
      value: 'G',
    },
    {
      label: 'ยังไม่มีงานทำ',
      value: 'P',
    },
    {
      label: 'อื่น ๆ',
      value: 'O',
    },
  ],
  channel: [
    {
      label: 'ประกาศรับสมัครงาน',
      value: 'A',
    },
    {
      label: 'สมัครด้วยตนเอง',
      value: 'B',
    },
    {
      label: 'ได้รับการแนะนำจาก ตัวแทน/ผู้บริหาร',
      value: 'C',
    },
    {
      label: 'ได้รับการแนะนำจาก พนักงาน',
      value: 'D',
    },
    {
      label: 'โอนมาจากพนักงาน',
      value: 'E',
    },
    {
      label: 'ช่องทางออนไลน์',
      value: 'F',
    },
  ],
  addressType: [
    {
      label: 'อาศัยกับครอบครัว',
      value: 'H',
    },
    {
      label: 'เช่าอยู่',
      value: 'R',
    },
    {
      label: 'ผ่อน',
      value: 'M',
    },
    {
      label: 'เป็นของตัวเอง',
      value: 'O',
    },
  ],
  salary: [
    {
      label: 'ต่ำกว่า 5,000',
      value: 'A',
    },
    {
      label: 'ระหว่าง 5,001 - 10,000',
      value: 'B',
    },
    {
      label: 'ระหว่าง 10,001 - 20,000',
      value: 'C',
    },
    {
      label: 'มากกว่า 20,000',
      value: 'D',
    },
  ],
  YesNo: [
    {
      label: 'มี',
      value: 'YES',
    },
    {
      label: 'ไม่มี',
      value: 'NO',
    },
  ],
  YesNo2: [
    {
      label: 'เคย',
      value: 'YES',
    },
    {
      label: 'ไม่เคย',
      value: 'NO',
    },
  ],
  NoYes: [
    {
      label: 'ไม่ใช่',
      value: 'NO',
    },
    {
      label: 'ใช่',
      value: 'YES',
    },
  ],
  NoYes2: [
    {
      label: 'ไม่เคย',
      value: 'NO',
    },
    {
      label: 'เคย',
      value: 'YES',
    },
  ],
}

export const PROVINCES = [
  {
    value: '08b4a056-a652-45e5-8ca3-89c5598497e2',
    label: 'กรุงเทพมหานคร',
    labelEN: 'Bangkok',
  },
  {
    value: '2e1d009d-7592-4574-bacc-da1c328cf420',
    label: 'สมุทรปราการ',
    labelEN: 'Samut Prakan',
  },
  {
    value: '0f7c43f9-4e68-4dbf-befc-849b08b1b183',
    label: 'นนทบุรี',
    labelEN: 'Nonthaburi',
  },
  {
    value: '62add299-f0a8-4685-96cb-d806a2669aeb',
    label: 'ปทุมธานี',
    labelEN: 'Pathum Thani',
  },
  {
    value: '758ada17-22e9-428e-924a-09c0bb98c839',
    label: 'พระนครศรีอยุธยา',
    labelEN: 'Phra Nakhon Si Ayutthaya',
  },
  {
    value: 'b45b4807-c490-4553-9126-853f0958348b',
    label: 'อ่างทอง',
    labelEN: 'Ang Thong',
  },
  {
    value: 'acc221b4-fe85-49e2-848c-86497456277b',
    label: 'ลพบุรี',
    labelEN: 'Loburi',
  },
  {
    value: '472a008d-a34f-4ff3-8f00-f029a974f9fc',
    label: 'สิงห์บุรี',
    labelEN: 'Sing Buri',
  },
  {
    value: '59cffbfe-68fd-4519-ab97-34a86bc41664',
    label: 'ชัยนาท',
    labelEN: 'Chai Nat',
  },
  {
    value: 'b377cd97-0ace-4a7b-a45b-09f17bffb166',
    label: 'สระบุรี',
    labelEN: 'Saraburi',
  },
  {
    value: '828ce34f-f2a9-45ff-86e5-b810b5c80dd0',
    label: 'ชลบุรี',
    labelEN: 'Chon Buri',
  },
  {
    value: '01e432c0-3012-4edf-b6fb-3341763dabf1',
    label: 'ระยอง',
    labelEN: 'Rayong',
  },
  {
    value: 'd4e72bc4-4884-4b28-81da-c9380b5ddb79',
    label: 'จันทบุรี',
    labelEN: 'Chanthaburi',
  },
  {
    value: '7da0ac86-82ec-4192-acc0-742ef6809069',
    label: 'ตราด',
    labelEN: 'Trat',
  },
  {
    value: '9c30806e-bc35-4112-ac18-ae160238535b',
    label: 'ฉะเชิงเทรา',
    labelEN: 'Chachoengsao',
  },
  {
    value: '4033b9d7-4bf2-4ec6-98c1-51abcc30e55d',
    label: 'ปราจีนบุรี',
    labelEN: 'Prachin Buri',
  },
  {
    value: '2318db8b-e251-4e3f-9135-ecdebdf2b0f6',
    label: 'นครนายก',
    labelEN: 'Nakhon Nayok',
  },
  {
    value: '6822213c-093d-4f0f-b5f4-ba1a5f9028eb',
    label: 'สระแก้ว',
    labelEN: 'Sa Kaeo',
  },
  {
    value: 'ad47f192-252b-4678-8b67-55f3fb33ad8b',
    label: 'นครราชสีมา',
    labelEN: 'Nakhon Ratchasima',
  },
  {
    value: '73fa382a-e99a-4c72-807b-ef699d4bf332',
    label: 'บุรีรัมย์',
    labelEN: 'Buri Ram',
  },
  {
    value: 'a657cd8e-2c3d-4f2f-b45a-a7f2154d514a',
    label: 'สุรินทร์',
    labelEN: 'Surin',
  },
  {
    value: 'd3048589-fe60-4b98-a0a2-8bbcd5a4d0d5',
    label: 'ศรีสะเกษ',
    labelEN: 'Si Sa Ket',
  },
  {
    value: 'b6735114-a48b-4833-a9c2-becdfd9c04e1',
    label: 'อุบลราชธานี',
    labelEN: 'Ubon Ratchathani',
  },
  {
    value: '366da24b-f573-4d1b-8303-6ae26b7919c4',
    label: 'ยโสธร',
    labelEN: 'Yasothon',
  },
  {
    value: 'd2c523f0-de27-436e-bc0b-e7eaec9c37b7',
    label: 'ชัยภูมิ',
    labelEN: 'Chaiyaphum',
  },
  {
    value: 'ba10d192-eb38-46f0-81e4-46fb5dd8404f',
    label: 'อำนาจเจริญ',
    labelEN: 'Amnat Charoen',
  },
  {
    value: '69b934a9-d0fb-46e3-b5e5-4dd6ca263c2f',
    label: 'หนองบัวลำภู',
    labelEN: 'Nong Bua Lam Phu',
  },
  {
    value: 'fc0528cc-8554-44e7-a5d8-b331d52baa7a',
    label: 'ขอนแก่น',
    labelEN: 'Khon Kaen',
  },
  {
    value: 'fe203cd2-b2d5-4480-8a7f-9b9edd78d08c',
    label: 'อุดรธานี',
    labelEN: 'Udon Thani',
  },
  {
    value: '1818ba50-85d6-4237-8fd5-2eb7cdab92f7',
    label: 'เลย',
    labelEN: 'Loei',
  },
  {
    value: '0f4d4073-6078-4fdb-be32-3053be3ab876',
    label: 'หนองคาย',
    labelEN: 'Nong Khai',
  },
  {
    value: 'd024848c-c88c-4e2b-b1b6-ec65339a562a',
    label: 'มหาสารคาม',
    labelEN: 'Maha Sarakham',
  },
  {
    value: '6fc8063e-7e96-47e9-b80f-c92ac85b5c73',
    label: 'ร้อยเอ็ด',
    labelEN: 'Roi Et',
  },
  {
    value: 'fb3716c3-01bc-4b6f-93a4-44c05ae1c970',
    label: 'กาฬสินธุ์',
    labelEN: 'Kalasin',
  },
  {
    value: '40de781f-de86-4149-b262-fc0d19c53393',
    label: 'สกลนคร',
    labelEN: 'Sakon Nakhon',
  },
  {
    value: '2d22c57a-c251-4bad-9609-0b7b31545f50',
    label: 'นครพนม',
    labelEN: 'Nakhon Phanom',
  },
  {
    value: 'da65d1c7-e2d2-49e4-a810-65edc2aa9afc',
    label: 'มุกดาหาร',
    labelEN: 'Mukdahan',
  },
  {
    value: '06f8e32a-c83d-45a2-bef9-cd79476e66ec',
    label: 'เชียงใหม่',
    labelEN: 'Chiang Mai',
  },
  {
    value: 'afca38b9-c23a-4d30-a83d-998cd9afe529',
    label: 'ลำพูน',
    labelEN: 'Lamphun',
  },
  {
    value: 'eac12f9a-e545-440e-a9a2-4995456da27a',
    label: 'ลำปาง',
    labelEN: 'Lampang',
  },
  {
    value: '74863893-56db-4659-843f-9f90377e7bd8',
    label: 'อุตรดิตถ์',
    labelEN: 'Uttaradit',
  },
  {
    value: 'b62ab431-7496-4196-9756-2d6f35e45043',
    label: 'แพร่',
    labelEN: 'Phrae',
  },
  {
    value: '5e811997-877f-4e7f-a99f-977ced6edd7f',
    label: 'น่าน',
    labelEN: 'Nan',
  },
  {
    value: 'bbdbb60e-99e1-41a1-a9b5-feeada36ad0e',
    label: 'พะเยา',
    labelEN: 'Phayao',
  },
  {
    value: '6597028d-3777-40fc-8df0-f7f14e8609f3',
    label: 'เชียงราย',
    labelEN: 'Chiang Rai',
  },
  {
    value: '00f67c87-45c5-4432-9397-692a4151d37a',
    label: 'แม่ฮ่องสอน',
    labelEN: 'Mae Hong Son',
  },
  {
    value: '86076aa1-767e-4336-8330-5dd8290ba18f',
    label: 'นครสวรรค์',
    labelEN: 'Nakhon Sawan',
  },
  {
    value: 'b0f7feba-3d3f-4351-918d-8c283c170a82',
    label: 'อุทัยธานี',
    labelEN: 'Uthai Thani',
  },
  {
    value: '24475e10-3ff9-4fe8-8855-a4127b20c324',
    label: 'กำแพงเพชร',
    labelEN: 'Kamphaeng Phet',
  },
  {
    value: '54c02a21-cc36-4c14-b44f-5a722aa99ef6',
    label: 'ตาก',
    labelEN: 'Tak',
  },
  {
    value: 'db438008-124f-4680-9b75-0c8836685942',
    label: 'สุโขทัย',
    labelEN: 'Sukhothai',
  },
  {
    value: 'de4d4c78-3ee3-46c0-80e5-5ec9caecbf21',
    label: 'พิษณุโลก',
    labelEN: 'Phitsanulok',
  },
  {
    value: '2edc1fe6-06bf-4329-adde-9205bb441509',
    label: 'พิจิตร',
    labelEN: 'Phichit',
  },
  {
    value: '8d7d45be-5b91-45cd-96f9-4a23d629d206',
    label: 'เพชรบูรณ์',
    labelEN: 'Phetchabun',
  },
  {
    value: 'ba18ceb6-627e-44f9-a016-7d617451bda6',
    label: 'ราชบุรี',
    labelEN: 'Ratchaburi',
  },
  {
    value: 'c7425d4e-9b3c-47ba-a5bf-ddcd0531824f',
    label: 'กาญจนบุรี',
    labelEN: 'Kanchanaburi',
  },
  {
    value: 'f72e8b2f-2cab-4391-8ef2-631a277e848e',
    label: 'สุพรรณบุรี',
    labelEN: 'Suphan Buri',
  },
  {
    value: '5a131660-d138-4eab-a2dd-244c1e57d1f0',
    label: 'นครปฐม',
    labelEN: 'Nakhon Pathom',
  },
  {
    value: 'f705195d-d229-4601-a507-b443d77ccfde',
    label: 'สมุทรสาคร',
    labelEN: 'Samut Sakhon',
  },
  {
    value: '4635098b-efcb-4058-b281-07b6806fcaeb',
    label: 'สมุทรสงคราม',
    labelEN: 'Samut Songkhram',
  },
  {
    value: '0e5564d0-ac56-44d8-9c86-81b19e3dfac2',
    label: 'เพชรบุรี',
    labelEN: 'Phetchaburi',
  },
  {
    value: '4a9c3f6e-b9ee-400e-b06b-b570d0f8e746',
    label: 'ประจวบคีรีขันธ์',
    labelEN: 'Prachuap Khiri Khan',
  },
  {
    value: '259a77d2-1daa-47b4-a4ad-d79c1230d6c1',
    label: 'นครศรีธรรมราช',
    labelEN: 'Nakhon Si Thammarat',
  },
  {
    value: '579c6d6e-3f31-4160-b167-bf38e5483509',
    label: 'กระบี่',
    labelEN: 'Krabi',
  },
  {
    value: '70b6d3ee-8519-4198-8a69-5b8cb8db5234',
    label: 'พังงา',
    labelEN: 'Phangnga',
  },
  {
    value: '802412a0-9080-48c8-91a5-da70919b940d',
    label: 'ภูเก็ต',
    labelEN: 'Phuket',
  },
  {
    value: 'f479f94e-b5aa-4caa-8a77-ed051d001643',
    label: 'สุราษฎร์ธานี',
    labelEN: 'Surat Thani',
  },
  {
    value: '35c1105d-24fe-4b91-abd2-cf2b059154a0',
    label: 'ระนอง',
    labelEN: 'Ranong',
  },
  {
    value: 'f9878cac-388f-45aa-b205-f48c91c37a78',
    label: 'ชุมพร',
    labelEN: 'Chumphon',
  },
  {
    value: '7172fe77-ff6c-472e-b473-e7c02cb5605c',
    label: 'สงขลา',
    labelEN: 'Songkhla',
  },
  {
    value: '7b62ab7f-b535-46dc-996a-f6dbc1e21fe9',
    label: 'สตูล',
    labelEN: 'Satun',
  },
  {
    value: 'ea05db1a-52ac-4725-8466-7040cc0740d8',
    label: 'ตรัง',
    labelEN: 'Trang',
  },
  {
    value: '230a2f01-99d3-4e2b-b597-ea5d603817c3',
    label: 'พัทลุง',
    labelEN: 'Phatthalung',
  },
  {
    value: '40ab4bc6-302d-4b5b-949c-90a82653059e',
    label: 'ปัตตานี',
    labelEN: 'Pattani',
  },
  {
    value: '9cb3540f-8044-4f0d-adaa-4a7a6f4cdd15',
    label: 'ยะลา',
    labelEN: 'Yala',
  },
  {
    value: '4b63bb95-da60-4f98-99e1-de3e438993ae',
    label: 'นราธิวาส',
    labelEN: 'Narathiwat',
  },
  {
    value: '1525f936-c9fe-4815-80c2-11b3b33cc19d',
    label: 'บึงกาฬ',
    labelEN: 'Buogkan',
  },
]

export const getLabelFullFormArrText = (
  path: CRUDFormData,
  value: string | undefined | null
) => {
  const find = _.find(path, { value: value })
  return _.get(find, 'label', '-')
}
