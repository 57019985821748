import {
  EXPORT_TYPE,
  EXPORT_TYPE_TH,
} from '../../../../../../constants/dc-admin'

export const moreMenu = {
  OIC: [
    { label: EXPORT_TYPE_TH.APPLICANT, value: EXPORT_TYPE.APPLICANT },
    { label: EXPORT_TYPE_TH.RECEIPT, value: EXPORT_TYPE.RECEIPT },
    {
      label: EXPORT_TYPE_TH.WITHDRAWAL_SLIP,
      value: EXPORT_TYPE.WITHDRAWAL_SLIP,
    },
  ],
  SUCCESS: [
    { label: EXPORT_TYPE_TH.REPORT, value: EXPORT_TYPE.REPORT },
    { label: EXPORT_TYPE_TH.VERIFY_FAILED, value: EXPORT_TYPE.VERIFY_FAILED },
  ],
  RCMS: [{ label: EXPORT_TYPE_TH.RCMS, value: EXPORT_TYPE.RCMS }],
}
