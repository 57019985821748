import AdminPanelSettingsTwoTone from '@mui/icons-material/AdminPanelSettingsTwoTone'
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone'
import TableViewIcon from '@mui/icons-material/TableView'
import PersonIcon from '@mui/icons-material/Person'
import ManageAccountsTwoTone from '@mui/icons-material/ManageAccountsTwoTone'
import { getUserPermissions } from '../../utils/validatePermission'

import * as _ from 'lodash'
import { filterMenuByPermissions } from '../../utils/filterMenuByPermissions'

export const checkColor = (status: boolean) => {
  return status ? 'primary.light' : 'unset'
}

export interface MenuItemType {
  name: string
  icon: (active: boolean) => JSX.Element
  active: boolean
  path: string
  list: {
    name: string
    active: boolean
    path: string
  }[]
}

export function leftMenuList(): MenuItemType[] {
  const menu = [
    {
      name: 'Dashboard',
      permission: 'DASHBOARD',
      module: 'DC_ADMIN',
      icon: (active: boolean) => (
        <AssignmentTwoToneIcon
          fontSize="small"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/home',
      list: [],
    },
    {
      name: 'ผู้สมัคร',
      module: 'AGENCY_ADMIN',
      icon: (active: boolean) => (
        <PersonIcon fontSize="small" sx={{ color: checkColor(active) }} />
      ),
      active: false,
      path: '/prospect',
      list: [
        {
          name: 'รายการผู้สมัคร',
          active: false,
          path: '/prospect',
          permission: 'CANDIDATE',
        },
        {
          name: 'รายการผู้มุ่งหวัง',
          active: false,
          path: '/candidate',
          permission: 'CANDIDATE',
        },
      ],
    },
    {
      name: 'ตรวจสอบใบสมัคร',
      module: ['DC_ADMIN', 'MANPOWER_ADMIN'],
      icon: (active: boolean) => (
        <AssignmentTwoToneIcon
          fontSize="small"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/application-verification',
      list: [
        ...(window.__env__.TOGGLE_DC_DASHBOARD === 'ON' // Check if the toggle is "ON"
          ? [
              {
                name: 'ภาพรวม',
                active: false,
                path: '/application-verification/dashboard',
                permission: 'DASHBOARD',
              },
            ]
          : []),
        {
          name: 'รายการ',
          active: false,
          path: '/application-verification/list',
          permission: ['DC_ADMIN', 'ATTACHMENT_DOWNLOAD_AND_REVIEW_UPLOAD'],
        },
      ],
      // list: [
      //   {
      //     name: 'ภาพรวม',
      //     active: false,
      //     path: '/application-verification/dashboard',
      //     permission: 'DASHBOARD',
      //   },
      //   {
      //     name: 'รายการ',
      //     active: false,
      //     path: '/application-verification/list',
      //     permission: 'DC_ADMIN',
      //   },
      // ],
    },
    {
      name: 'Assign Rule',
      module: 'AGENCY_ADMIN',
      icon: (active: boolean) => (
        <TableViewIcon fontSize="small" sx={{ color: checkColor(active) }} />
      ),
      active: false,
      path: '/assign-rule',
      list: [
        {
          name: 'Manager Queue',
          active: false,
          path: '/manager-queue',
          permission: 'ASSIGN_RULE',
        },
        {
          name: 'Nearby Area',
          active: false,
          path: '/nearby-area',
          permission: 'ASSIGN_RULE',
        },
      ],
    },
    {
      name: 'Master Data',
      module: 'SUPER_ADMIN',
      icon: (active: boolean) => (
        <AdminPanelSettingsTwoTone
          fontSize="small"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/master-data',
      list: [
        {
          name: 'ประเภทเหตุุผลการปฎิเสธนัดสัมภาษณ์',
          active: false,
          path: '/reject-appointment',
          permission: 'MASTER_DATA',
        },
        {
          name: 'ประเภทเหตุุผลการปฎิเสธหลังสัมภาษณ์',
          active: false,
          path: '/reject-after-interview',
          permission: 'MASTER_DATA',
        },
        {
          name: 'รู้จักเราจากช่องทาง',
          active: false,
          path: '/know-us-from-channel',
          permission: 'MASTER_DATA',
        },
        {
          name: 'แหล่งย่อยทางสังคม',
          active: false,
          path: '/social-sub-source',
          permission: 'MASTER_DATA',
        },
        {
          name: 'แหล่งย่อยออฟไลน์',
          active: false,
          path: '/offline-sub-source',
          permission: 'MASTER_DATA',
        },
        {
          name: 'แหล่งย่อยอีเว้นท์',
          active: false,
          path: '/event-sub-source',
          permission: 'MASTER_DATA',
        },
        {
          name: 'เหตุผลร้องขอบันทึก',
          active: false,
          path: '/reason-request-memo',
          permission: 'MASTER_DATA',
        },
        {
          name: 'เหตุผลการละเว้นผู้มุ่งหวังโดย DC',
          active: false,
          path: '/reasons-drop-prospect-by-dc',
          permission: 'MASTER_DATA',
        },
        {
          name: 'โปรไฟล์ RAM',
          active: false,
          path: '/ram-profile',
          permission: 'MASTER_DATA',
        },
        {
          name: 'โปรไฟล์ SRAM',
          active: false,
          path: '/sram-profile',
          permission: 'MASTER_DATA',
        },
        {
          name: 'โปรไฟล์ CADTO',
          active: false,
          path: '/cadto-profile',
          permission: 'MASTER_DATA',
        },
        {
          name: 'โปรไฟล์ CDO',
          active: false,
          path: '/cdo-profile',
          permission: 'MASTER_DATA',
        },
        {
          name: 'ลายมือชื่อ',
          active: false,
          path: '/signature-document',
          permission: 'MASTER_DATA',
        },
      ],
    },
    {
      name: 'Manage',
      module: 'SUPER_ADMIN',
      icon: (active: boolean) => (
        <ManageAccountsTwoTone
          fontSize="small"
          sx={{ color: checkColor(active) }}
        />
      ),
      active: false,
      path: '/manage',
      list: [
        {
          name: 'ข้อมูลพนักงาน',
          active: false,
          path: '/manage/staff-profile',
          permission: 'PERMISSION',
        },
        {
          name: 'สิทธิ์การใช้งาน',
          active: false,
          path: '/manage/permission',
          permission: 'PERMISSION',
        },
      ],
    },
  ]

  const storedUser = localStorage.getItem('user')
  const user = storedUser ? JSON.parse(storedUser) : null

  if (user) {
    const userPermissions = getUserPermissions(user)
    const uniqPermissions = _.uniqBy(userPermissions, 'permission')

    const filteredMenu = filterMenuByPermissions(menu, uniqPermissions)

    const uniqPermissionsAccess = [
      ...new Set([
        ...user.permissionGroup.flatMap((group: { permission: any[] }) =>
          group.permission.map((perm: { permission: any }) => perm.permission)
        ),
        ...user.permission.map((perm: { permission: any }) => perm.permission),
      ]),
    ]
    user.uniqPermissions = uniqPermissionsAccess
    localStorage.setItem('user', JSON.stringify(user))

    return _.uniq(filteredMenu)
  } else {
    return menu
  }
}
