// /* eslint-disable @typescript-eslint/no-explicit-any */

import Typography from '@mui/material/Typography'
import CardBox from '../CardBox'
import { StyledSubBox, StyledSubContainer } from './styled'
import { useSelector } from '../../../../../../../redux/store'
import _ from 'lodash'
import { useTheme } from '@mui/material'
import { dashboardItem } from '../../model/dashboardItem'

const WaitForApproveBox = () => {
  const field = 'waitingForApprove'
  const data = useSelector((state) =>
    _.get(state.dcAdmin.dashboard.data, field, 0)
  )
  const theme = useTheme()
  const item = dashboardItem(theme)[field]
  return (
    <CardBox isFullWidth item={item} total={_.get(data, 'total', 0)}>
      <StyledSubContainer>
        <SubBoxItem label="CCM" value={_.get(data, 'ccm', 0)} />
        <SubBoxItem label="RAM/SRAM/CADTO/CDO" value={_.get(data, 'ramSRamCadtoCdo', 0)} />
      </StyledSubContainer>
    </CardBox>
  )
}

export default WaitForApproveBox

export const SubBoxItem = ({ label, value }: any) => {
  return (
    <StyledSubBox>
      <Typography color="text.gray">{label}</Typography>
      <Typography variant="body1b" color="text.gray">
        {value}
        <span style={{ marginLeft: 12 }}>งาน</span>
      </Typography>
    </StyledSubBox>
  )
}
