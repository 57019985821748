import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleIcon from '@mui/icons-material/Circle'
import styled from '@mui/material/styles/styled'
import { Theme } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
// redux
import {
  selectCheckListStatus,
  selectInitialProfile,
} from '../../../redux/selector'
import { applicationStatusStep } from '../slices/applicant-details'
import _ from 'lodash'
import { useSelector } from 'react-redux'

const AccordionStyle = styled(Accordion)(({ theme }) => ({
  minHeight: 56,
  alignItems: 'center',
  border: `1px solid ${theme.palette.blue.blueBorder}`,
  borderRadius: 8,
  padding: theme.spacing(2),
  marginTop: theme.spacing(3),

  '&.MuiAccordion-root': {
    boxShadow: 'none',
    padding: 0,

    '::before': {
      content: 'none', // * remove before pseudo
    },

    '&:first-of-type': {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },

    '&.Mui-expanded:first-of-type': {
      marginTop: theme.spacing(3),
    },
  },
}))

export default function Checklist() {
  const theme = useTheme()

  const initProfile = useSelector(selectInitialProfile)
  const checkListStatus = useSelector(selectCheckListStatus)

  if (!checkListStatus) {
    return null
  }

  return (
    <AccordionStyle disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlinedIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          '&.MuiAccordionSummary-root.Mui-expanded': {
            borderBottom: `1px solid ${theme.palette.blue.blueBorder}`,
          },
        }}
      >
        <Stack
          width={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="body1b" color="text.primary" mr={1}>
              รายการตรวจสอบ:
            </Typography>
            <Typography variant="body1b" color="primary.main">
              {checkListStatus === applicationStatusStep.DS_REVIEWED
                ? 'รอดำเนินการ'
                : 'ตรวจสอบใบสมัคร'}
            </Typography>
          </Box>
          <Typography variant="body2" color="text.primary">
            ผู้รับงาน: {_.get(initProfile, 'contractor', '-')}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          '&.MuiAccordionDetails-root': {
            paddingY: theme.spacing(4),
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={3}
        >
          <Stack alignItems="center">
            <CheckCircleIcon
              fontSize="large"
              sx={{ color: getColorFromStatus(checkListStatus, theme) }}
            />
            <Typography
              variant="h6Reg"
              sx={{
                color: getColorFromStatus(checkListStatus, theme),
              }}
              mt={1}
              textAlign="center"
            >
              ตรวจสอบใบสมัคร
            </Typography>
          </Stack>

          {/* RENDER DOTS */}
          <Stack direction="row" spacing={1}>
            {Array.from({ length: 6 }, (_, i) => `circle-elem-${i}`).map(
              (item) => (
                <CircleIcon
                  key={item}
                  sx={{
                    color: getColorFromStatus(checkListStatus, theme),
                    fontSize: '4px',
                  }}
                />
              )
            )}
          </Stack>

          <Stack alignItems="center">
            <CheckCircleIcon
              fontSize="large"
              sx={{ color: getColorFromStatus(checkListStatus, theme) }}
            />
            <Typography
              variant="h6Reg"
              mt={1}
              textAlign="center"
              sx={{ color: getColorFromStatus(checkListStatus, theme) }}
            >
              ตรวจสอบประวัติ
            </Typography>
          </Stack>
        </Stack>
      </AccordionDetails>
    </AccordionStyle>
  )
}

function getColorFromStatus(
  checkListStatus: keyof typeof applicationStatusStep,
  theme: Theme
) {
  if (checkListStatus === applicationStatusStep.DS_REVIEWED) {
    return theme.palette.action.disabled
  }

  return theme.palette.primary.main
}
