import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector, store } from '../../../redux/store'
import { selectEnhancedTable, selectTableSearch } from '../../../redux/selector'
import { setSearch } from '../redux/'
import { BoxSearch, SearchInput, StyledSearchIcon } from './styled'
import { handleSearchTable } from '../../CRUD/handler/handleSeaerchTable'
import debounce from 'lodash/debounce'

type DebouncedFunction = {
  (value: string): void
  cancel: () => void
}

const SearchCustom: React.FC = () => {
  const { table } = store.getState().table
  const dispatch = useDispatch()
  const { search } = useSelector(selectTableSearch)
  const [searchTable, setSearchTable] = useState(search.searchText || '')
  const { optionSearch } = useSelector(selectEnhancedTable)

  const debounceRef = useRef<DebouncedFunction>()
  useEffect(() => {
    debounceRef.current = debounce((value: string) => {
      dispatch(setSearch(value))
      handleSearchTable(table, value)(dispatch)
    }, 500) as DebouncedFunction

    return () => {
      const currentDebounce = debounceRef.current
      currentDebounce?.cancel()
    }
  }, [])

  const handleChange = useCallback((value: string) => {
    debounceRef.current?.(value)
    setSearchTable(value)
  }, [])

  useEffect(() => {
    if (search.searchText === '') setSearchTable('')
  }, [search])

  return (
    <>
      <BoxSearch>
        <SearchInput
          data-testid="search-input"
          placeholder={search.placeholder}
          onChange={(e) => handleChange(e.target.value)}
          value={searchTable}
          inputProps={{
            maxLength: search.maxLength,
          }}
        />
        <StyledSearchIcon data-testid="search-icon" color="primary" />
      </BoxSearch>
      {optionSearch}
    </>
  )
}

export default SearchCustom
