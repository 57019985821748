import { type Dispatch, type SetStateAction } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// redux
import { useSelector } from '../../../../redux/store'
import { selectProfileDetails } from '../../../../redux/selector'
//
import HeaderActions from './HeaderActions'
import ListItem from './ListItem'
import {
  CONFLICT_OF_INTEREST,
  EVER_NEVER_OPTIONS,
} from '../../../../constants/status'

type Props = {
  setTab: Dispatch<SetStateAction<number>>
}

export default function PoliciesStep({ setTab }: Readonly<Props>) {
  const profileDetails = useSelector(selectProfileDetails)

  if (!profileDetails) {
    return null
  }

  return (
    <Stack spacing={3}>
      {/* HEADER ACTIONS SECTION */}
      <HeaderActions titleStep="Policy" setTab={setTab} />

      {/* LIST ITEM SECTION */}
      <Box>
        {/* LIST ITEM HEADER */}
        <Typography variant="body1b">นโยบาย</Typography>

        <Stack
          minHeight={56}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="tableHeader" flex={1} pl={2}>
            หัวข้อ
          </Typography>
          <Typography variant="tableHeader" flex={1}>
            ข้อมูลที่กรอก
          </Typography>
        </Stack>

        {/* LIST ITEM CONTENT */}
        <ListItem
          listData={[
            {
              label:
                'คุณมีความขัดแย้ง ทางผลประโยชน์ หรือความขัดแย้ง ในการปฏิบัติหน้าที่ ที่ประสงค์จะเปิดเผยหรือไม่?',
              value:
                CONFLICT_OF_INTEREST[
                  profileDetails.policy.conflictOfInterest
                ] ?? '-',
            },
            {
              label: 'โปรดให้รายละเอียด',
              value: profileDetails.policy.conflictOfInterestInfo ?? '-',
            },
            {
              label: 'ท่านเคยถูกศาลพิพากษาล้มละลายมาก่อนหรือไม่',
              value: EVER_NEVER_OPTIONS[profileDetails.policy.bankrupt] ?? '-',
            },
            {
              label: 'โปรดให้รายละเอียด',
              value: profileDetails.policy.bankruptInfo ?? '-',
            },
            {
              label:
                'ท่านเคยถูกดำเนินคดีอาญา และ/หรือ ถูก ศาลพิพากษาลงโทษในคดีอาญามาก่อนหรือไม่',
              value:
                EVER_NEVER_OPTIONS[profileDetails.policy.criminalCase] ?? '-',
            },
            {
              label: 'โปรดให้รายละเอียด',
              value: profileDetails.policy.criminalCaseInfo ?? '-',
            },
            {
              label:
                'ท่านได้รับการมอบอำนาจให้เป็นตัวแทนหรือนายหน้าที่ทำหน้าที่พิจารณารับประกันภัย และพิจารณาสินไหมในนามบริษัท หรือไม่?',
              value: EVER_NEVER_OPTIONS[profileDetails.policy.authorization],
            },
            {
              label:
                'ท่านเป็นคนกลางประกันภัย ทำหน้าที่เป็นผู้แนะนำธุรกิจให้บริษัทฯ โดยไม่มีใบอนุญาตตัวแทน/นายหน้า และต้องการค่าแนะนำธุรกิจหรือไม่? *',
              value:
                EVER_NEVER_OPTIONS[profileDetails.policy.insuranceIntermediary],
            },
            {
              label:
                'ท่านเป็นคู่ค้าทางธุรกิจรายใหญ่/พันธมิตรทางธุรกิจรายใหญ่ของบริษัท เช่น ธนาคารที่เป็นนายหน้าให้แก่บริษัท เช่น KTB หรือไม่?',
              value:
                EVER_NEVER_OPTIONS[profileDetails.policy.majorBusinessPartners],
            },
          ]}
        />
      </Box>
    </Stack>
  )
}
