import { Box, Typography, Collapse, IconButton } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { CustomCard } from '../../../../../components/Layout/CustomCard'
import { displayDateTime } from '../../../../../utils/convertFormatDateTime'
import { SliceModel } from '../../../../../redux/models'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useState } from 'react'
import BoxText from '../../../../../components/Layout/BoxText'
import {
  getStatusDisplayText,
  Keys,
  ProcessResultApprovalStatus,
} from '../../../../../constants/status'
import { DateFormat } from '../../../../../constants/dateFormat'

export default function CardInterview() {
  const { data } = useSelector(
    (state: SliceModel) => ({
      data: state.prospect.detail.data,
    }),
    shallowEqual
  )

  const [open, setOpen] = useState<{ [key: string]: boolean }>({})

  if (_.size(data?.interview) === 0) return null

  const handleToggle = (uuid: string | null | undefined) => {
    if (typeof uuid !== 'string') return

    setOpen((prev) => ({ ...prev, [uuid]: !prev[uuid] }))
  }

  return (
    <>
      <Box
        display={'flex'}
        mb={2}
        justifyContent="space-between"
        alignItems={'center'}
      >
        <Box pt={1}>
          <Typography variant="h5" color={'primary'}>
            ข้อมูลการสัมภาษณ์
          </Typography>
        </Box>
      </Box>
      {data?.interview?.map((interview) => (
        <CustomCard sx={{ mb: { xs: 2, sm: 3 } }} key={interview.uuid}>
          <Box
            display={'flex'}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display={'flex'} gap={2}>
              <Typography sx={{ color: 'text.gray' }}>
                {interview.status ===
                ProcessResultApprovalStatus.I_PASSED_INTERVIEW
                  ? 'วันที่ผ่านสัมภาษณ์'
                  : 'วันที่ไม่ผ่านสัมภาษณ์'}
              </Typography>
              <Typography variant="body1b">
                {displayDateTime(interview?.updatedAt, DateFormat.DDMMYYYYHHmm)}{' '}
                น.
              </Typography>

              <Typography sx={{ color: 'text.gray' }}>สถานะ </Typography>
              <Typography variant="body1b">
                {getStatusDisplayText(interview?.status as Keys) || '-'}
              </Typography>
            </Box>
            <IconButton onClick={() => handleToggle(interview.managerUuid)}>
              <ExpandMoreIcon />
            </IconButton>
          </Box>
          <Collapse
            in={open[interview.managerUuid]}
            timeout="auto"
            unmountOnExit
          >
            <Box display={'flex'} gap={2}>
              <Typography sx={{ color: 'text.gray' }}>ผู้จัดการ :</Typography>
              <Typography variant="body1b">
                {interview?.manager?.name || '-'}
              </Typography>

              <Typography sx={{ color: 'text.gray' }}>รหัส :</Typography>
              <Typography variant="body1b">
                {interview?.manager?.code || '-'}
              </Typography>
            </Box>

            <Typography sx={{ py: 1, color: 'text.gray' }}>
              รายละเอียดเพิ่มเติม
            </Typography>

            <BoxText>
              {interview?.reasonInterviewText && (
                <Typography variant="h6" color={'error'}>
                  {interview?.reasonInterviewText || '-'}
                </Typography>
              )}

              {interview?.comment && (
                <Typography variant="body2">
                  {interview?.comment || '-'}
                </Typography>
              )}
            </BoxText>
          </Collapse>
        </CustomCard>
      ))}
    </>
  )
}
