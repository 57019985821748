import { useEffect } from 'react'
import { fetchData, fetchOnScroll } from '../../handler/fetchData'
import { shallowEqual, useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import _ from 'lodash'
import NotiBox from '../../../components/NotiBox'
import { LoadingCircular } from '../../styled'

const ListContent = () => {
  const { type, result, isLoading } = useSelector(
    (state: any) => ({
      page: state.notification.notification.listPage.page,
      type: state.notification.notification.listPage.type,
      result: state.notification.notification.listPage.result,
      isLoading: state.notification.notification.listPage.isLoading,
    }),
    shallowEqual
  )
  useEffect(() => {
    fetchData(true)
  }, [type])

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement
    if (scrollTop + clientHeight >= scrollHeight - 100 && !isLoading) {
      fetchOnScroll()
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Box pt={2}>
      {_.defaultTo(result, []).length > 0 ? (
        <>
          {_.defaultTo(result, []).map((item: any, index: number) => {
            return <NotiBox key={index} data={item} />
          })}
        </>
      ) : (
        <Typography
          variant="h5"
          sx={{
            my: 5,
            color: '#7F7F7F',
            textAlign: 'center',
            fontSize: 24,
          }}
        >
          ไม่มีรายการ
        </Typography>
      )}

      {isLoading && (
        <Box display="flex" justifyContent="center">
          <LoadingCircular isLoading />
        </Box>
      )}
    </Box>
  )
}

export default ListContent
