import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import { dispatch, useSelector } from '../../../../redux/store'
import { CustomCard } from '../../../../components/Layout/CustomCard'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useEffect, useState } from 'react'
import { getHistory } from './handler/getHistory'
import {
  selectCrudFormData,
  selectInitialProfile,
} from '../../../../redux/selector'
import CustomButton from '../../../../components/Input/CustomButton'
import { setFieldValue } from '../../../../components/CRUD/slices'
import _ from 'lodash'
import { UploadMultiReturnPath } from '../../../../components/FullUpload'
import { DecisionStatus } from './types/formBody'
import LinearProgress from '@mui/material/LinearProgress'
import useLocalStorage from '@rehooks/local-storage'
import { permissionItem } from '../../../../constants/permission'

export const HistoryDecisionInfo = (props: { isEdit?: boolean }) => {
  const { isEdit = false } = props
  const [user] = useLocalStorage('user')
  const uniqPermissions = _.get(user, 'uniqPermissions', [])
  const isPermission = _.includes(uniqPermissions, permissionItem.DC_ADMIN)
  const initProfile = useSelector(selectInitialProfile)
  const fullFormUuid = String(initProfile?.fullformUuid)
  const formData = useSelector(selectCrudFormData)
  const results: any[] = _.get(formData, 'historyDecision', [])
  const [isLoading, setIsLoading] = useState(false)
  const getStatusText = (status: string, actionBy = '') => {
    if (actionBy === 'CCM' && status === DecisionStatus.COMPLETED)
      return 'ตรวจสอบแล้ว'
    switch (status) {
      case DecisionStatus.COMPLETED:
        return 'ผ่าน'
      case DecisionStatus.REJECTED:
        return 'ไม่ผ่าน'
      case DecisionStatus.PENDING:
        return 'กำลังพิจารณา'
      default:
        return '-'
    }
  }

  useEffect(() => {
    const _loading = async () => {
      setIsLoading(true)
      if (fullFormUuid) await dispatch(getHistory(fullFormUuid))
      setIsLoading(false)
    }
    _loading()
  }, [])
  return (
    <>
      <Box sx={{ minHeight: '300px' }}>
        {results.map((result, index) => (
          <CustomCard key={index} sx={{ p: 0, mb: 2 }}>
            <Accordion
              sx={{
                borderRadius: 1,
                overflow: 'none',
                boxShadow: 'none',
                border: 'none',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Box sx={{ width: '50%' }}>
                    <Typography variant={'body1b'} color={'primary.main'}>
                      {result.actionBy}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography variant={'body2'} color={'text.gray'}>
                      สถานะ
                    </Typography>
                    <Typography variant={'body1b'}>
                      {getStatusText(result.status, result.actionBy)}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>

              <AccordionDetails sx={{ mb: 0 }}>
                {['RAM', 'SRAM', 'CADTO', 'CDO'].includes(result.actionBy) && (
                  <Box
                    sx={{
                      display: 'flex',
                      borderTop: 'solid 1px #f1f1f1',
                      pt: 2,
                    }}
                  >
                    <Box sx={{ width: '33%' }}>
                      <Typography variant={'body2'} color={'text.gray'}>
                        ชื่อ
                      </Typography>
                      <Typography variant={'body2b'}>
                        {_.get(result.data, 'name', '-')}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '33%' }}>
                      <Typography variant={'body2'} color={'text.gray'}>
                        เบอร์โทรศัพท์
                      </Typography>
                      <Typography variant={'body2b'}>
                        {_.get(result.data, 'phoneNumber', '-')}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '33%' }}>
                      <Box>
                        <Typography variant={'body2'} color={'text.gray'}>
                          อีเมล
                        </Typography>
                      </Box>

                      <Typography variant={'body2b'}>
                        {_.get(result.data, 'email', '-')}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {['RAM', 'SRAM'].includes(result.actionBy) && (
                  <Box
                    sx={{
                      display: 'flex',
                      mb: 1,
                    }}
                  >
                    <Box sx={{ width: '33%' }}>
                      <Typography variant={'body2'} color={'text.gray'}>
                        เลขา
                      </Typography>
                      <Typography variant={'body1b'}>
                        {_.get(result.data, 'secretaryNameTh', '-')}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '33%' }}>
                      <Typography variant={'body2'} color={'text.gray'}>
                        เบอร์โทรศัพท์
                      </Typography>
                      <Typography variant={'body2b'}>
                        {_.get(result.data, 'secretaryPhoneNumber', '-')}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '33%' }}>
                      <Typography variant={'body2'} color={'text.gray'}>
                        อีเมล
                      </Typography>
                      <Typography variant={'body2b'}>
                        {_.get(result.data, 'secretaryEmail', '-')}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {['RAM', 'SRAM'].includes(result.actionBy) && (
                  <Box
                    sx={{
                      mb: 1,
                    }}
                  >
                    <Box>
                      <Typography variant={'body2'} color={'text.gray'}>
                        รายละเอียด
                      </Typography>
                      <Typography variant={'body2b'}>
                        {_.isEmpty(result.detail) ? '-' : result.detail}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {['CCM', 'RAM', 'SRAM'].includes(result.actionBy) && (
                  <Box>
                    <Typography variant={'body2'} color={'text.gray'}>
                      เอกสารแนบ
                    </Typography>
                    <UploadMultiReturnPath
                      name={''}
                      fullFormUuid={''}
                      isView
                      defFiles={_.get(result, 'AttachmentMeta', []) ?? []}
                    />
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
            {isEdit && result.actionBy !== 'CCM' && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  gap: 1,
                  p: 1,
                  borderTop: 'solid 1px #f1f1f1',
                }}
              >
                <CustomButton
                  variant={'outlined'}
                  disabled={result.status !== 'PENDING'}
                  onClick={() => {
                    dispatch(
                      setFieldValue({
                        key: `formData.actionBy`,
                        value: 'Request',
                      })
                    )
                    dispatch(
                      setFieldValue({
                        key: `formData.actionForm`,
                        value: result.actionBy,
                      })
                    )
                  }}
                >
                  ผลการพิจารณา
                </CustomButton>
                <CustomButton
                  variant={'contained'}
                  disabled={
                    !isPermission ||
                    result.confirmStatus !== 'PENDING' ||
                    result.status === 'PENDING'
                  }
                  onClick={() => {
                    dispatch(
                      setFieldValue({
                        key: `formData.actionBy`,
                        value: 'Confirm',
                      })
                    )
                    dispatch(
                      setFieldValue({
                        key: `formData.actionForm`,
                        value: result.actionBy,
                      })
                    )
                  }}
                >
                  ยืนยันผล
                </CustomButton>
              </Box>
            )}
          </CustomCard>
        ))}
        {isLoading && <LinearProgress />}
        {!isLoading && _.size(results) === 0 && (
          <Typography>ไม่มีรายการประวัติการส่งพิจารณาเพิ่มเติม</Typography>
        )}
      </Box>
    </>
  )
}
