import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  getNotificationFilter,
  clearNotification,
} from '../../services/notification'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { setCountNotification, setResult } from './slices'
// import NotiBox from './components/NotiBox'
import NotiBox from './components/NotiBox'
import _ from 'lodash'
import { AppDispatch } from '../../redux/store'

const NotificationView = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [anchorEl, setAnchorEl] = useState(null)
  const [isInit, setIsInit] = useState(false)

  const { notification } = useSelector(
    (state: any) => state?.notification,
    shallowEqual
  )

  useEffect(() => {
    fetchNotification()
  }, [])

  const defaultUser = {
    uuid: '5f601dd4-12bb-48e2-9aa9-e019f7c7b2f5',
    name: 'แอดมิน ผู้ดูแล',
    email: 'tanvadee.rerkyai@krungthai-axa.co.th',
  }

  const fetchNotification = async () => {
    const response = await dispatch(
      getNotificationFilter({
        limit: 5,
        page: 1,
        user: defaultUser,
      })
    )
    const data = response.payload
    const { countNotification, result, countTotal } = data
    dispatch(setCountNotification(countNotification))
    dispatch(
      setResult({
        result: result,
        limit: 5,
        page: 1,
        countTotal: countTotal,
      })
    )
    setIsInit(true)
  }

  useEffect(() => {
    if (anchorEl) {
      clearNoti()
    } else {
      if (isInit) {
        fetchNotification()
      }
    }
  }, [anchorEl])

  const clearNoti = async () => {
    await dispatch(clearNotification({ user: defaultUser }))
    dispatch(setCountNotification(0))
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClickViewAll = () => {
    window.location.href = `/notifications`
  }

  const open = Boolean(anchorEl)
  const id = open ? 'noti-popover' : undefined

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Button
        aria-describedby={id}
        sx={{ position: 'relative', minWidth: 29 }}
        onClick={handleClick}
      >
        <NotificationsIcon sx={{ width: 30, height: 30 }} />
        {notification.countNotification > 0 && (
          <Box
            sx={{
              position: 'absolute',
              right: 6,
              top: 6,
              width: 15,
              height: 15,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: (theme) => theme.palette.error.main,
              color: '#FFFFFF',
              borderRadius: 50,
            }}
          >
            <Typography variant="tooltip" sx={{ lineHeight: 0 }}>
              {notification.countNotification}
            </Typography>
          </Box>
        )}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 0, horizontal: 285 }}
      >
        <Box sx={{ minWidth: 329, px: 2, pt: 2, pb: 1 }}>
          {_.get(notification, `result`, []).length > 0 ? (
            _.get(notification, `result`, []).map(
              (item: any, index: number) => {
                return <NotiBox key={index} data={item} />
              }
            )
          ) : (
            <Typography
              variant="h5"
              sx={{
                my: 5,
                color: '#7F7F7F',
                textAlign: 'center',
                fontSize: 24,
              }}
            >
              ไม่มีรายการ
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 1,
              cursor: 'pointer',
            }}
            onClick={() => handleClickViewAll()}
          >
            <Typography
              sx={{
                fontWeight: 700,
                color: (theme) => theme?.palette.primary.main,
              }}
            >
              ดูทั้งหมด
            </Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}
export default NotificationView
