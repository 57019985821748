import {
  startLoading,
  stopLoading,
} from '../../../../../../components/Table/redux'
import { store } from '../../../../../../redux/store'
import { downloadFile } from '../../../../../../services/utils/downloadFile'
import { dcAdminExport } from '../../../../../../utils/apiPath'
import { mutateFilterPropToBody } from '../mutation/filterPropToBody'
import {
  EXPORT_TYPE_TH,
  EXPORT_TYPE,
} from '../../../../../../constants/dc-admin'
import _ from 'lodash'
import { handleExportOIC } from './handleExportOIC'
import { handleExportFullFormReceipt } from './handleExportFullFormReceipt'
import { handleExportFullFormWithdrawal } from './handleExportFullFormWithdrawal'
import { handleExportRcms } from './handleExportRcms'

export const handleMoreMenuClick = async (value: string) => {
  store.dispatch(startLoading())
  const body = {
    ...mutateFilterPropToBody(),
    downloadType: value,
  }

  if (value === EXPORT_TYPE.APPLICANT) {
    await handleExportOIC()
  } else if (value === EXPORT_TYPE.RECEIPT) {
    await handleExportFullFormReceipt()
  } else if (value === EXPORT_TYPE.WITHDRAWAL_SLIP) {
    await handleExportFullFormWithdrawal()
  } else if (value === EXPORT_TYPE.RCMS) {
    await handleExportRcms()
  } else {
    store.dispatch(
      downloadFile({
        url: dcAdminExport,
        body: body,
        fileName: `${_.get(EXPORT_TYPE_TH, value, 'file')}.xlsx`,
      })
    )
  }

  store.dispatch(stopLoading())
}
