import { typeEnum } from '../../list/enums/typeEnum'

interface NotificationListPage {
  type: string
  page: number
  result: Array<any>
  limit: number
  hasMore: boolean
  countTotal: number
  isLoading: boolean
}

export interface NotificationState {
  isLoading: boolean
  result: Array<any>
  page: number
  limit: number
  countNotification: number
  countTotal: number
  listPage: NotificationListPage
}

export const initialState: NotificationState = {
  isLoading: false,
  result: [],
  page: 1,
  limit: 5,
  countNotification: 0,
  countTotal: 0,
  listPage: {
    type: typeEnum.ALL,
    page: 1,
    result: [],
    limit: 8,
    hasMore: true,
    countTotal: 0,
    isLoading: false,
  },
}
