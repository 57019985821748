import { Typography, Box } from '@mui/material'
import { breadcrumb } from './model/breadcrumb'
import Button from '@mui/material/Button'
import FilterList from '@mui/icons-material/FilterList'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import { useDispatch, useSelector } from '../../../../../redux/store'
import CFilterDrawer from '../../../../../components/CRUD/components/FilterDrawer'
import { setToggleFilterDrawer } from '../../../../../components/Table/redux'
import { defaultFilter } from './model/defaultFilter'
import { filterItems } from './model/filterItems'
import { BoxButton, BoxHeader } from './styled'
import { handleChange } from '../../../../../components/CRUD/handler/handleChange'
import { handleFiltering } from '../../../../../components/CRUD/handler/handleFiltering'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import { selectTableFilterTotal } from '../../../../../redux/selector'

const Header = () => {
  const { filterTotal } = useSelector(selectTableFilterTotal)
  const dispatch = useDispatch()
  return (
    <BoxHeader>
      <Box>
        <Typography variant="h4">ตรวจสอบใบสมัคร</Typography>
        <Breadcrumbs menuList={breadcrumb} />
      </Box>
      <BoxButton>
        <Button
          startIcon={<FilterList />}
          onClick={() => dispatch(setToggleFilterDrawer(true))}
        >
          ตัวกรอง {filterTotal !== 0 && `(${filterTotal})`}
        </Button>
      </BoxButton>

      <CFilterDrawer
        handleChange={handleChange}
        handleFiltering={() => handleFiltering(mutateFilterSateToProp)}
        filterItems={filterItems}
        defaultFilter={defaultFilter}
      />
    </BoxHeader>
  )
}

export default Header
