import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import React from 'react'
import relativeTime from 'dayjs/plugin/relativeTime'
import localeData from 'dayjs/plugin/localeData'
import { isReadNotification } from '../../../../services/notification'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../redux/store'
import { NotiBoxStyled } from '../../list/styled'

// Load the plugins
dayjs.extend(relativeTime)
dayjs.extend(localeData)
// Set Thai locale
dayjs.locale('th')

const NotiBox = ({ data = null }) => {
  const dispatch = useDispatch<AppDispatch>()
  const details = _.get(data, 'content.details', '-')
  const url = _.get(data, 'content.url', '-')
  const staff: any = _.get(data, 'staff')
  const isRead = _.get(data, 'isRead')
  const notificationUuid = _.get(data, 'uuid')
  const createdAt = _.get(data, 'createdAt', '-')

  const handleOnClick = () => {
    setRead()
  }

  const setRead = async () => {
    await dispatch(isReadNotification({ uuid: notificationUuid })).unwrap()
    window.location.href = `${url}`
  }

  return (
    <NotiBoxStyled onClick={handleOnClick}>
      <Box width="100%">
        <Typography variant="body1b">{details}</Typography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1" color="#707070">
            {staff?.firstName} {staff?.lasttName}
          </Typography>
          <Typography variant="body1" color="#707070">
            {calculateTimeDifference(createdAt)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: 8,
        }}
      >
        {!isRead && (
          <Box
            sx={{
              width: 8,
              height: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#494DF4',
              borderRadius: 50,
            }}
          ></Box>
        )}
      </Box>
    </NotiBoxStyled>
  )
}

function calculateTimeDifference(targetTime: any): any {
  const currentTime = dayjs() // Get the current time
  const givenTime = dayjs(targetTime) // Parse the given time

  if (!givenTime.isValid()) {
    return `-`
  }

  const differenceInHours = currentTime.diff(givenTime, 'hour')

  if (differenceInHours < 0) {
    return `อีก ${Math.abs(differenceInHours)} ชม.`
  } else {
    return `${differenceInHours} ชม.`
  }
}

export default NotiBox
