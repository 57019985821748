import { type MouseEvent, useState } from 'react'

import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import ApplicationReview from './ApplicationReview'
import BackgroundReview from './BackgroundReview'
import Notes from './Notes'
import { TabFeature } from './model/tab'
import { AdditionalSubmissionHistoryDrawer, EditHistoryDrawer } from './Drawer'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { selectInitialProfile } from '../../../redux/selector'

function a11yProps(index: number) {
  return {
    id: `tab-feature-${index}`,
    'aria-controls': `tabpanel-feature-${index}`,
  }
}

export type TabPanelProps = {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-feature-${index}`}
      aria-labelledby={`tab-feature-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  )
}

export default function TabFeatures() {
  const [value, setValue] = useState(0)

  const [openDrawer, setOpenDrawer] = useState<Record<string, boolean>>({
    editHistory: false,
    additionalSubmissionHistory: false,
  })
  const initProfile = useSelector(selectInitialProfile)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const toggleDrawer = (newValue: boolean, name: string) => () => {
    setOpenDrawer({ [name]: newValue })
  }

  const handleOpenDrawer = (event: MouseEvent<HTMLButtonElement>) => {
    const { name } = event.currentTarget

    setOpenDrawer({ [name]: true })
  }

  return (
    <Box width={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {/* TABS */}
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs features"
          sx={{ '& .MuiTabs-indicator': { height: 4 } }}
        >
          <Tab
            label={
              <Typography
                variant={value === 0 ? 'body1b' : 'body1'}
                color="primary.main"
              >
                ตรวจสอบใบสมัคร
              </Typography>
            }
            {...a11yProps}
          />
          <Tab
            disabled={_.isNull(initProfile?.contractor)}
            label={
              <Typography
                variant={value === 1 ? 'body1b' : 'body1'}
                color="primary.main"
              >
                ตรวจสอบประวัติ
              </Typography>
            }
            {...a11yProps}
          />
          <Tab
            disabled={_.isNull(initProfile?.contractor)}
            label={
              <Typography
                variant={value === 2 ? 'body1b' : 'body1'}
                color="primary.main"
              >
                หมายเหตุ
              </Typography>
            }
            {...a11yProps}
          />
        </Tabs>

        {/* {value === TabFeature.BackgroundReview && (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<HistoryIcon color="primary" />}
            sx={{ gap: 1 }}
            onClick={() => {
              dispatch(
                openCustomDialog({
                  type: DIALOG_TYPE_STRING.CUSTOM,

                  content: <HistoryDecision isEdit={false} />,
                  boxSx: { pr: 0, pe: 0 },
                })
              )
            }}
            name="additionalSubmissionHistory"
          >
            <Typography variant="buttonm">
              ดูประวัติการส่งพิจารณาเพิ่มเติม
            </Typography>
          </Button>
        )} */}
      </Stack>

      {/* TAB PANEL */}

      {/* APPLICATION REVIEW */}
      <CustomTabPanel value={value} index={TabFeature.ApplicationReview}>
        <ApplicationReview />
      </CustomTabPanel>

      {/* BACKGROUND REVIEW */}
      <CustomTabPanel value={value} index={TabFeature.BackgroundReview}>
        <BackgroundReview />
      </CustomTabPanel>

      {/* NOTES */}
      <CustomTabPanel value={value} index={TabFeature.Notes}>
        <Notes />
      </CustomTabPanel>

      {/* DRAWER */}
      <AdditionalSubmissionHistoryDrawer
        open={openDrawer['additionalSubmissionHistory']}
        toggleDrawer={toggleDrawer}
      />

      <EditHistoryDrawer
        open={openDrawer['editHistory']}
        toggleDrawer={toggleDrawer}
      />
    </Box>
  )
}
