import { shallowEqual, useSelector } from 'react-redux'
import { notificationTypes } from '../../models/type'
import Button from '@mui/material/Button'
import { handleOnClickTab } from '../../handler/handleOnClickTabs'

const HeaderTab = () => {
  const { NotiType } = useSelector(
    (state: any) => ({
      NotiType: state.notification.notification.listPage.type,
    }),
    shallowEqual
  )
  return (
    <>
      {notificationTypes.map((item, index) => {
        return (
          <Button
            key={index}
            variant={NotiType === item.value ? 'contained' : 'text'}
            sx={{ borderRadius: 25, mr: 2 }}
            onClick={() => handleOnClickTab(item.value)}
          >
            {item.label}
          </Button>
        )
      })}
    </>
  )
}

export default HeaderTab
