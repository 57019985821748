// /* eslint-disable @typescript-eslint/no-explicit-any */

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { CircularProgressProps, useTheme } from '@mui/material'
import CardBox from '../CardBox'
import { dashboardItem } from '../../model/dashboardItem'
import _ from 'lodash'
import { useSelector } from '../../../../../../../redux/store'

const SuccessBox = () => {
  const field = 'success'
  const data = useSelector((state) =>
    _.get(state.dcAdmin.dashboard.data, field, 0)
  )
  const theme = useTheme()
  const item = dashboardItem(theme)[field]
  return (
    <CardBox item={item} total={_.get(data, 'total', 0)}>
      <Box
        sx={{
          width: 97,
          minWidth: 97,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: '#F8FAFC',
          p: 1.5,
          ml: 2,
          borderRadius: 3,
        }}
      >
        <Box>
          <Typography variant="body2" color="text.gray">
            ระยะเวลาเฉลี่ยในการดำเนินการแต่ละเคส
          </Typography>
          <Typography
            color="text.gray"
            sx={{ fontSize: 12, lineHeight: '20px' }}
          >
            (ต่อเดือน)
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <DurationProgress />
          <Typography variant="body1b" color="text.gray">
            {_.get(data, 'averageDuration', 0)} วัน
          </Typography>
        </Box>
      </Box>
    </CardBox>
  )
}

export default SuccessBox

export const DurationProgress = (props: CircularProgressProps) => {
  return (
    <Box sx={{ position: 'relative', height: 32 }}>
      <CircularProgress
        {...props}
        variant="determinate"
        sx={(theme) => ({ color: theme.palette?.primary?.main })}
        size={32}
        thickness={6}
        value={100}
      />
      <CircularProgress
        {...props}
        disableShrink
        variant="determinate"
        size={32}
        thickness={6}
        value={60}
        sx={(theme) => ({
          color: theme.palette?.text?.mercury,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
        })}
      />
      <img
        src="/icons/icon-time-history.svg"
        style={{ position: 'absolute', left: 6, top: 7 }}
      />
    </Box>
  )
}
