import _ from 'lodash'
import { store } from '../../../../redux/store'
import { getNotificationFilter } from '../../../../services/notification'
import {
  setListPageLoading,
  setListPagePage,
  setListPageResult,
} from '../../slices'
import { typeEnum } from '../enums/typeEnum'

const defaultUser = {
  uuid: '5f601dd4-12bb-48e2-9aa9-e019f7c7b2f5',
  name: 'แอดมิน ผู้ดูแล',
  email: 'tanvadee.rerkyai@krungthai-axa.co.th',
}

export const fetchData = async (isinit: boolean = false) => {
  const { notification } = store.getState().notification
  const type = notification.listPage.type
  let page = notification.listPage.page
  if (isinit) {
    page = 1
  }
  store.dispatch(setListPageLoading(true))
  const response = await store.dispatch(
    getNotificationFilter({
      limit: 5,
      page: page,
      user: defaultUser,
      isRead: type === typeEnum.UNREADS ? false : undefined,
    })
  )
  const data = response.payload
  store.dispatch(
    setListPageResult({
      countTotal: _.get(data, 'countTotal', []),
      result: _.get(data, 'result', []),
    })
  )
  store.dispatch(setListPagePage(page))
  store.dispatch(setListPageLoading(false))
}

export const fetchOnScroll = () => {
  const { notification } = store.getState().notification
  if (notification.listPage.hasMore && !notification.listPage.isLoading) {
    const page = notification.listPage.page + 1 //next page
    store.dispatch(setListPagePage(page))
    fetchData()
  }
}
